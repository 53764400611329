import { DataSourceType } from "@coeff/api";

export const DataSourceTypeDisplayNames: {
  [dsType in DataSourceType]: string;
} = {
  csv: "CSV",
  google_sheets: "Google Sheets",
  salesforce: "Salesforce",
  mysql: "MySQL",
  gpt_copilot: "GPT Copilot",
  redshift: "Redshift",
  postgres: "PostgreSQL",
  snowflake: "Snowflake",
  mssql: "Microsoft SQL Server",
  bigquery: "BigQuery",
  looker: "Looker",
  chargebee: "Chargebee",
  chargify: "Chargify",
  google_ads: "Google Ads",
  google_analytics: "Google Analytics (UA)",
  google_analytics_4: "Google Analytics 4",
  facebook_ads: "Facebook Ads",
  hubspot: "HubSpot",
  marketo: "Marketo",
  netsuite: "NetSuite",
  quickbooks: "QuickBooks",
  shopify: "Shopify",
  stripe: "Stripe",
  tableau: "Tableau",
  jira: "Jira",
  zendesk: "Zendesk",
  google_drive: "Google Drive",
  airtable: "Airtable",
  amplitude: "Amplitude",
  gong: "Gong",
  klaviyo: "Klaviyo",
  mailchimp: "Mailchimp",
  mongodb: "MongoDB",
  outreach: "Outreach",
  pipedrive: "Pipedrive",
  sendgrid: "Sendgrid",
  excel: "Excel",
  one_drive: "One Drive",
  google_search_console: "Google Search Console",
  cloud_table: "Dataset",
  notion: "Notion",
  greenhouse: "Greenhouse",
  xero: "Xero",
  custom_api: "Connect Any API",
  web_scraper: "Web Scraper",
  linkedin_ads: "LinkedIn Ads",
  facebook_insights: "Facebook Insights",
  instagram_insights: "Instagram Insights",
  linkedin_pages: "LinkedIn Pages",
  youtube_analytics: "YouTube Analytics",
  microsoft_ads: "Microsoft Ads",
  asana: "Asana",
  razorpay: "Razorpay",
  clickup: "ClickUp",
  typeform: "Typeform",
  harvest: "Harvest",
  freshdesk: "Freshdesk",
  paymo: "Paymo",
  google_calendar: "Google Calendar",
  trello: "Trello",
  calendly: "Calendly",
  smartsheet: "Smartsheet",
  reddit_ads: "Reddit Ads",
  surveymonkey: "SurveyMonkey",
  quora_ads: "Quora Ads",
  podio: "Podio",
  gusto: "Gusto",
  intercom: "Intercom",
  etsy: "Etsy",
  pinterest_ads: "Pinterest Ads",
  active_campaign: "ActiveCampaign",
  basecamp: "Basecamp",
  jotform: "JotForm",
  zohobooks: "Zoho Books",
  coda: "Coda",
  square: "Square",
  zoom: "Zoom",
  webflow: "Webflow",
  zoho_crm: "Zoho CRM",
  bigin: "Bigin",
  lever: "Lever",
  salesloft: "Salesloft",
  woo_commerce: "WooCommerce",
  wordpress: "WordPress",
  bigcommerce: "BigCommerce",
  smartlead: "Smartlead",
  affinity: "Affinity",
  shiprocket: "Shiprocket",
  databricks: "Databricks SQL",
  rippling: "Rippling",
  apollo: "Apollo",
  sage: "Sage Intacct",
  aws_billing: "AWS Billing",
  gmail: "Gmail",
  google_classroom: "Google Classroom",
};

export const DataSourceTypeDisplayShortNames: {
  [dsType in DataSourceType]: string;
} = {
  // Default to full display names, with overrides defined as needed
  ...DataSourceTypeDisplayNames,
  google_analytics_4: "GA 4",
  google_analytics: "GA",
  google_search_console: "GSC",
  youtube_analytics: "YT Analytics",
  facebook_insights: "FB Insights",
  instagram_insights: "Insta Insights",
  custom_api: "Any API",
  mssql: "MS SQL Server",
};
