import { LookerConnectionEmailTriggerOptions } from "@coeff/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message } from "antd";
import React, { useState, useEffect, ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  ImportTypeIcon,
  Typography,
  Alert,
  CoeLogo,
  Lock,
  ErrorMessage,
  Loader,
} from "../components";
import { COLORS } from "../constants";
import {
  DataSourceTypeDisplayNames,
  getBaseUrl,
  sentryCapture,
  useApiContext,
  useTrack,
} from "../utils";

import { AddLookerConnection, LookerHelpCard } from "./LookerOAuthContent";

const WholePageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const PageFooter = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  bottom: 0;
  padding: 30px;
  width: 100%;
  max-width: 970px;
`;

const FormGroup = styled.div`
  display: flex;

  label {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }
`;

const ConnectorInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0;
`;

const CoeLogoContainer = styled.div`
  padding-left: 16px;
`;

const StyledParagraph = styled.div`
  color: ${COLORS.black45};
`;

const FormLayout = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;

  .leftSection {
    flex: 1;
  }

  .rightSection {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 32px;

    .rightSection {
      minwidth: 280px;
      width: 280px;
      display: flex;
    }
  }
`;

const StyledAlert = styled(Alert)`
  margin: 12px 0;
  padding: 15px;
  background-color: #f2e3ff !important;
  border: none;
`;

const INVITE_EXPIRED = {
  title: "Looker Invite Link Expired",
  message: `The Looker invitation link has expired. Please request a new invitation to connect your Looker account.`,
};

const INVITE_COMPLETED = {
  title: "Looker Invite completed",
  message: `The Looker invitation has been completed.`,
};

const withLayout = (component: ReactElement, footer?: ReactElement) => {
  return (
    <WholePageLayout>
      <Container>
        <PageHeader>
          <CoeLogoContainer>
            <CoeLogo />
          </CoeLogoContainer>

          <StyledParagraph>
            Coefficient let’s teams easily work with company data within Sheets.
          </StyledParagraph>
        </PageHeader>

        {component}
      </Container>
      {footer}
    </WholePageLayout>
  );
};

type UserDetails = {
  is_expired: boolean;
  is_completed: boolean;
  user_name: string;
};

export const RequestLookerSetup: React.FC = () => {
  const { apiClient: api } = useApiContext();
  const location = useLocation();
  const uuid = new URLSearchParams(location.search).get("uuid");

  const [formError, setFormError] = useState<{
    title: string;
    message: string;
  }>();

  const track = useTrack();
  const history = useHistory();

  useEffect(() => {
    document.title = "Looker Setup - Coefficient";
    track("looker_setup_viewed", {
      looker_request_access_uuid: uuid!,
    });
    track("conn_create_started", {
      data_source: DataSourceTypeDisplayNames.looker,
      import_data_source: "looker",
      conn_data_source: "looker",
      looker_version: 2,
      via_looker_admin: true,
      looker_request_access_uuid: uuid!,
    });
  }, []);

  const {
    data: userDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryCacheKey.LOOKER_REQUEST_INFO_BY_UUID, uuid],
    queryFn: async () => {
      if (!uuid) {
        throw new Error("UUID is required");
      }

      const response = await api.lookerFetchUrlBasedAuthDetails({
        looker_fetch_url_based_auth_details_request: {
          attached_uuid: uuid,
        },
      });

      return response.data;
    },
    cacheTime: 0,
    staleTime: 0,
    retry: 2,
    enabled: Boolean(uuid),
    onError: () => {
      message.error("Failed to fetch user details");
    },
  });

  const validationMutation = useMutation({
    mutationFn: async ({
      baseUrl,
      clientId,
      clientSecret,
    }: {
      baseUrl: string;
      clientId: string;
      clientSecret: string;
    }) => {
      const credentials = {
        url: getBaseUrl(baseUrl),
        client_id: clientId,
        client_secret: clientSecret,
      };

      const response = await api.lookerCheckConnection({
        looker_check_connection_request: { credentials },
      });

      if (response.data.error) {
        setFormError({
          title: response.data.title || "Could not verify Client ID and Secret",
          message:
            response.data.source_error ||
            "Could not verify credentials. Please check your inputs and try again.",
        });
        throw new Error("Failed to validate connection");
      }

      const lookerMeta = await api.lookerGetDataSourceOwnerMetaData({
        looker_get_data_source_owner_meta_data_request: { credentials },
      });

      if (
        !lookerMeta.data.is_admin ||
        lookerMeta.data.already_configured ||
        userDetails?.is_gmail_like_domain
      ) {
        await api.createDataSource({
          create_data_source_request: {
            data_source: {
              credentials: {
                ...credentials,
                url: response.data.base_url,
                remote_auth_url_uuid: uuid!,
              },
              data_source_type: "looker",
              data_source_name: DataSourceTypeDisplayNames.looker,
            },
          },
        });

        track("conn_create_done", {
          data_source: DataSourceTypeDisplayNames.looker,
          import_data_source: "looker",
          via_looker_admin: true,
          looker_version: 2,
          looker_request_access_uuid: uuid!,
        });

        await api.triggerLookerConnEmail({
          trigger_looker_conn_email_request: {
            attached_uuid: uuid!,
            trigger_type: LookerConnectionEmailTriggerOptions.ClientSecretConnCreated,
          },
        });

        return {
          isAdmin: false,
          baseUrl,
          clientId,
          clientSecret,
        };
      }

      return { isAdmin: true, baseUrl, clientId, clientSecret };
    },
    onSuccess: ({ isAdmin, baseUrl, clientId, clientSecret }) => {
      history.replace("./complete", {
        uuid,
        baseUrl,
        clientId,
        clientSecret,
        isAdmin,
        name: userDetails?.user_name,
      });
    },
    onError: (error: Error | { title: string; message: string }) => {
      track("looker_setup_failed", {
        looker_request_access_uuid: uuid!,
        reason: error.message || "Failed to validate connection",
      });
      track("conn_create_failed", {
        data_source: DataSourceTypeDisplayNames.looker,
        import_data_source: "looker",
        reason: error.message || "Failed to validate connection",
        looker_version: 2,
        via_looker_admin: true,
        looker_request_access_uuid: uuid!,
      });
      message.error(error.message || "Failed to validate connection");
      sentryCapture({ error, name: "LookerRequestSetup: validationMutation" });
    },
  });

  if (!uuid || isError || userDetails?.is_expired) {
    return withLayout(<ErrorMessage {...INVITE_EXPIRED} />);
  }

  if (isLoading) {
    return withLayout(<Loader />);
  }

  if (userDetails?.is_completed) {
    return withLayout(
      <Alert
        type="success"
        message={INVITE_COMPLETED.title}
        description={INVITE_COMPLETED.message}
      />
    );
  }

  const [firstName, lastName] = userDetails.user_name.split(" ");

  return withLayout(
    <>
      <StyledAlert
        message={`${firstName} ${lastName} shared this page with you to help connect Looker to Sheets using Coefficient`}
        description={`Connecting to Looker requires your client ID & secret (that only admins can access). Enter it below and we’ll notify ${firstName} once it’s complete.`}
      />
      <ConnectorInfo>
        <ImportTypeIcon type="looker" />
        <div>
          <Typography fontWeight={700} fontSize={"28px"} lineHeight={"34px"}>
            Connect to Looker
          </Typography>
          <Typography fontSize="14px" lineHeight={"18px"}>
            <StyledParagraph>
              Connect to Looker using your URL, Client ID & Secret below
            </StyledParagraph>
          </Typography>
        </div>
      </ConnectorInfo>
      <Content>
        {formError && <ErrorMessage {...formError} />}
        <FormLayout>
          <Form className="leftSection">
            <FormGroup>
              <AddLookerConnection
                isLoading={validationMutation.isLoading}
                onAdd={options => validationMutation.mutate(options)}
                onError={setFormError}
              />
            </FormGroup>
          </Form>
          <LookerHelpCard />
        </FormLayout>
      </Content>
    </>,
    <PageFooter>
      <Lock fill={COLORS.black85} />
      <Typography isEllipsedContentTooltip>
        Coefficient encrypts your credentials and never stores your source data. Learn more
      </Typography>
    </PageFooter>
  );
};
