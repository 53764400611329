import { Skeleton } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";

import { useBillingDetails } from "../../../api/users";
import { Tooltip } from "../../../components";
import { COLORS } from "../../../constants";
import { BContext, PricingSummary } from "../../../types";
import { numberWithCommas, planNameFromType, pluralize } from "../../../utils";
import { BillingContext } from "../Billing";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  font-weight: 600;
  color: ${COLORS.black85};

  &.medium {
    font-size: 18px;
  }

  &.large {
    font-size: 20px;
    font-weight: bold;
  }

  .small {
    font-size: 12px;
    color: ${COLORS.black45};
    font-weight: normal;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    text-decoration-color: ${COLORS.black65};
    cursor: pointer;
  }

  &.whitebg {
    background: white;
    padding: 8px 16px 0px;
    margin: 12px -16px 0px;
    border-radius: 8px;
  }
`;

const MultiRowWhiteBG = styled.div`
  background: white;
  padding: 8px 16px;
  margin: 12px -16px 8px;
  border-radius: 8px;
  font-weight: 600;
  color: ${COLORS.black85};
`;

const StyledSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    .ant-skeleton-title,
    .ant-skeleton-paragraph > li {
      height: 12px;
      background-color: ${COLORS.black15};
    }
  }
`;

export const PriceSummary = () => {
  const {
    additionalSeats,
    invitedUserEmails,
    billingMetadata,
    priceSummary,
    selectedBillingInterval,
    selectedPlanType,
    selectedUsers,
    selectedDataSources,
  } = useContext(BillingContext) as BContext;

  const { usersCost, dataSourceCost, totalMonthlyCost, dueNowCost, annualDiscount, discountPrice } =
    priceSummary;

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const totalUsers = selectedUsers.length + additionalSeats + invitedUserEmails.length;

  const discountPercentage = billingMetadata?.coupon_discount_percentage;

  let amount_due = dueNowCost;
  let discount_coupon;
  let coupon_discount_price = 0;
  let loading = false;

  const { data, isLoading } = useBillingDetails(
    {
      temporary_front_end_estimated_amount: amount_due,
      plan_type: planData?.plan_type,
      billing_interval: planData?.billing_interval,
      num_users: totalUsers,
      num_data_sources: selectedDataSources.length,
      coupon_discount_percentage: discountPercentage,
    },
    { enabled: Boolean(planData) }
  );

  if (planData) {
    amount_due = data?.billing_details?.amount_due || dueNowCost;
    discount_coupon = data?.billing_details?.discount_coupon;
    coupon_discount_price = data?.billing_details?.discount_price || 0;
    loading = isLoading;
  }

  return loading ? (
    <StyledSkeleton active paragraph={{ rows: 7 }} title={false} />
  ) : (
    <>
      <Row>
        <span style={{ fontWeight: "normal" }}>
          {`${totalUsers ?? 1} ${
            selectedPlanType ? planNameFromType(selectedPlanType) : ""
          } User${pluralize(totalUsers)}`}
        </span>
        <span>
          $
          {numberWithCommas(
            usersCost + (selectedBillingInterval === "annually" ? annualDiscount : 0)
          )}
        </span>
      </Row>

      {dataSourceCost > 0 && (
        <Row>
          <Tooltip
            title={`${selectedDataSources.length - 1} additional data source${pluralize(
              selectedDataSources.length - 1
            )}. Each data source is $${
              planData?.per_additional_data_source_addon?.per_item_monthly_price_usd
            } / user / month`}
          >
            <span className="underline" style={{ fontWeight: "normal" }}>
              Additional data sources
            </span>
          </Tooltip>
          <span>${numberWithCommas(dataSourceCost)}</span>
        </Row>
      )}

      {annualDiscount > 0 ? (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>Annual plan savings</span>
          <span>-${numberWithCommas(annualDiscount)}</span>
        </Row>
      ) : null}

      {discountPercentage && (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>{discountPercentage}% Discount</span>
          <span>-${numberWithCommas(discountPrice)}</span>
        </Row>
      )}

      {!discountPercentage && discount_coupon && coupon_discount_price > 0 && (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>
            {discount_coupon === "TAKE50COEFF" ? "Discount" : discount_coupon} applied
          </span>
          <span>
            -$
            {numberWithCommas(
              discount_coupon === "TAKE50COEFF" && selectedBillingInterval === "annually"
                ? coupon_discount_price / 12
                : coupon_discount_price
            )}
          </span>
        </Row>
      )}

      <hr style={{ borderTop: `1px solid ${COLORS.black45}` }} />

      {selectedBillingInterval === "annually" && (
        <Row className="medium">
          <span>Total (Monthly)</span>
          <span>${numberWithCommas(totalMonthlyCost)}</span>
        </Row>
      )}

      <Row>
        <span>{selectedBillingInterval === "annually" ? "Due now" : "Total"}</span>
        <span>${numberWithCommas(amount_due)}</span>
      </Row>
    </>
  );
};

export const PriceSummaryProrated = ({
  editType,
  newPriceSummary: priceSummary,
}: {
  editType:
    | "downgrade-to-starter"
    | "upgrade-to-pro"
    | "add-seats"
    | "remove-seats"
    | "edit-billing-interval";
  newPriceSummary: PricingSummary;
}) => {
  const {
    additionalSeats,
    billingMetadata,
    selectedBillingInterval,
    selectedPlanType,
    selectedUsers,
    selectedDataSources,
    userSubscription,
  } = useContext(BillingContext) as BContext;

  const {
    usersCost,
    dataSourceCost,
    totalMonthlyCost,
    dueNowCost,
    annualDiscount,
    oneUserCost,
    oneDataSourceCost,
    discountPrice,
  } = priceSummary;

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const discountPercentage = billingMetadata?.coupon_discount_percentage;

  // The below 2 variables is used to calculate the amount paid if the plan is a starter.
  // This is used for calculation of pro-rated refund when upgrading to pro
  const oldStarterPlanData = billingMetadata?.plans.find(
    p =>
      p.plan_type === "starter" &&
      p.billing_interval === userSubscription?.subscription_details?.billing_interval
  );
  let oldStarterSubscriptionPrice =
    ((oldStarterPlanData?.per_user_plan?.per_item_monthly_price_usd ?? 0) +
      ((userSubscription?.subscription_details?.max_data_source_types ?? 1) - 1) *
        (oldStarterPlanData?.per_additional_data_source_addon?.per_item_monthly_price_usd ?? 0)) *
    (userSubscription?.subscription_details?.billing_interval === "annually" ? 12 : 1);

  if (discountPercentage) {
    oldStarterSubscriptionPrice =
      oldStarterSubscriptionPrice - (oldStarterSubscriptionPrice * discountPercentage) / 100;
  }

  const existingUserCount: number = userSubscription?.subscription_details?.max_users ?? 0;

  const additionalDataSourceCost: number =
    planData?.per_additional_data_source_addon?.per_item_monthly_price_usd ?? 0;

  let totalUsers = existingUserCount || 1;
  let amount_due = 0;
  let adjustment_amount = 0;
  if (editType === "add-seats") {
    totalUsers += additionalSeats;
    amount_due =
      (additionalSeats * oneUserCost +
        (selectedDataSources.length - 1) * additionalSeats * additionalDataSourceCost) *
      (selectedBillingInterval === "annually" ? 12 : 1);
  } else if (editType === "remove-seats") {
    totalUsers -= additionalSeats;
    amount_due =
      (additionalSeats * oneUserCost +
        (selectedDataSources.length - 1) * additionalSeats * additionalDataSourceCost) *
      (selectedBillingInterval === "annually" ? 12 : 1);
  } else if (editType === "edit-billing-interval") {
    amount_due = dueNowCost - totalMonthlyCost - annualDiscount;
    adjustment_amount = totalMonthlyCost + annualDiscount;
  } else if (editType === "upgrade-to-pro") {
    amount_due = dueNowCost - oldStarterSubscriptionPrice;
    adjustment_amount = oldStarterSubscriptionPrice;
  }

  const { data, isLoading } = useBillingDetails(
    {
      temporary_front_end_estimated_amount: amount_due,
      plan_type: planData?.plan_type,
      billing_interval: planData?.billing_interval,
      num_users: totalUsers,
      num_data_sources: selectedDataSources.length,
      coupon_discount_percentage: discountPercentage,
    },
    { enabled: Boolean(planData) }
  );

  if (!planData) {
    return null;
  }

  let coupon_discount_price = 0;
  if (data?.billing_details?.is_refund) {
    amount_due = data?.billing_details?.existing_credits || amount_due;
  } else {
    amount_due =
      data?.billing_details?.amount_due === undefined
        ? amount_due
        : data?.billing_details?.amount_due;
    adjustment_amount = data?.billing_details?.existing_credits || adjustment_amount;
  }

  const discount_coupon = data?.billing_details?.discount_coupon;
  coupon_discount_price = data?.billing_details?.discount_price || 0;

  return isLoading ? (
    <StyledSkeleton active paragraph={{ rows: 7 }} title={false} />
  ) : (
    <>
      <Row>
        <span style={{ fontWeight: "normal" }}>
          {existingUserCount || 1}{" "}
          {editType === "add-seats"
            ? `Existing + ${additionalSeats} User${pluralize(additionalSeats)}`
            : editType === "remove-seats"
            ? `Existing - ${additionalSeats} User${pluralize(additionalSeats)}`
            : `User${pluralize(existingUserCount)}`}
        </span>
        <span>
          $
          {numberWithCommas(
            usersCost + (selectedBillingInterval === "annually" ? annualDiscount : 0)
          )}
        </span>
      </Row>

      {dataSourceCost > 0 && (
        <Row>
          <span style={{ fontWeight: "normal" }}>Additional data sources</span>
          <span>${numberWithCommas(dataSourceCost)}</span>
        </Row>
      )}

      {annualDiscount > 0 ? (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>Annual plan savings</span>
          <span>-${numberWithCommas(annualDiscount)}</span>
        </Row>
      ) : null}

      {discountPercentage && (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>{discountPercentage}% Discount</span>
          <span>-${numberWithCommas(discountPrice)}</span>
        </Row>
      )}

      {!discountPercentage && discount_coupon && coupon_discount_price > 0 && (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>{discount_coupon} applied</span>
          <span>-${numberWithCommas(coupon_discount_price)}</span>
        </Row>
      )}

      {editType === "add-seats" && adjustment_amount > 0 && (
        <Row style={{ color: "#44AD10" }}>
          <span style={{ fontWeight: "normal" }}>Existing credits applied</span>
          <span>-${numberWithCommas(adjustment_amount)}</span>
        </Row>
      )}

      <hr style={{ borderTop: `1px solid ${COLORS.black45}` }} />

      <Row className="large">
        <span>Total per month</span>
        <span>${numberWithCommas(totalMonthlyCost)}</span>
      </Row>

      {selectedBillingInterval === "annually" && (
        <Row>
          <span>Total paid annually</span>
          <span>${numberWithCommas(dueNowCost)}</span>
        </Row>
      )}

      {editType === "add-seats" && (
        <Row className="large whitebg">
          <div style={{ marginRight: "12px" }}>
            Due now{" "}
            <div className="small" style={{ marginRight: "5px" }}>
              prorated charge for the current billing cycle
            </div>
          </div>
          <span>${numberWithCommas(amount_due)}</span>
        </Row>
      )}

      {editType === "remove-seats" && (
        <>
          <MultiRowWhiteBG>
            <Row className="large whitebg">
              <div style={{ marginRight: "12px" }}>Refund </div>
              <span>${numberWithCommas(amount_due)}</span>
            </Row>

            <Row className="large">
              <div className="small" style={{ marginRight: "5px" }}>
                prorated refund for the current billing cycle (will be credited to your Coefficient
                billing account)
              </div>
            </Row>
          </MultiRowWhiteBG>
        </>
      )}

      {editType === "edit-billing-interval" && (
        <>
          <MultiRowWhiteBG>
            <Row>
              <span style={{ marginRight: "5px" }}>
                Adjustment for remainder of current billing period
              </span>
              <span>-${numberWithCommas(adjustment_amount)}</span>
            </Row>

            <Row>
              <div style={{ marginRight: "12px" }}>
                Due now{" "}
                <div className="small" style={{ marginRight: "5px" }}>
                  You'll be charged on your card ending in{" "}
                  {userSubscription?.subscription_details?.cc_last_4_digits}
                </div>
              </div>
              <span>${numberWithCommas(amount_due)}</span>
            </Row>
          </MultiRowWhiteBG>
        </>
      )}

      {editType === "upgrade-to-pro" && (
        <>
          <MultiRowWhiteBG>
            <Row>
              <span style={{ marginRight: "5px" }}>
                Adjustment for remainder of current billing period
              </span>
              <span>
                -$
                {numberWithCommas(adjustment_amount)}
              </span>
            </Row>

            <Row>
              <div style={{ marginRight: "12px" }}>Due now</div>
              <span>${numberWithCommas(amount_due)}</span>
            </Row>
          </MultiRowWhiteBG>
        </>
      )}
    </>
  );
};

export const PriceSummaryProratedV2 = ({
  editType,
  newPriceSummary: priceSummary,
}: {
  editType:
    | "downgrade-to-starter"
    | "upgrade-to-pro"
    | "add-seats"
    | "remove-seats"
    | "edit-billing-interval";
  newPriceSummary: PricingSummary;
}) => {
  const {
    additionalSeats,
    billingMetadata,
    selectedBillingInterval,
    selectedPlanType,
    selectedUsers,
    selectedDataSources,
    userSubscription,
  } = useContext(BillingContext) as BContext;

  const {
    usersCost,
    dataSourceCost,
    totalMonthlyCost,
    dueNowCost,
    annualDiscount,
    oneUserCost,
    oneDataSourceCost,
    nonDiscountedOneUserCost,
    discountPrice,
  } = priceSummary;

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const discountPercentage = billingMetadata?.coupon_discount_percentage;

  // The below 2 variables is used to calculate the amount paid if the plan is a starter.
  // This is used for calculation of pro-rated refund when upgrading to pro
  const oldStarterPlanData = billingMetadata?.plans.find(
    p =>
      p.plan_type === "starter" &&
      p.billing_interval === userSubscription?.subscription_details?.billing_interval
  );
  let oldStarterSubscriptionPrice =
    ((oldStarterPlanData?.per_user_plan?.per_item_monthly_price_usd ?? 0) +
      ((userSubscription?.subscription_details?.max_data_source_types ?? 1) - 1) *
        (oldStarterPlanData?.per_additional_data_source_addon?.per_item_monthly_price_usd ?? 0)) *
    (userSubscription?.subscription_details?.billing_interval === "annually" ? 12 : 1);

  if (discountPercentage) {
    oldStarterSubscriptionPrice =
      oldStarterSubscriptionPrice - (oldStarterSubscriptionPrice * discountPercentage) / 100;
  }

  const billingInterval = userSubscription?.subscription_details?.billing_interval;

  const cycleMultiplier = billingInterval === "annually" ? 12 : 1;

  const existingUserCount: number = userSubscription?.subscription_details?.max_users ?? 0;

  const additionalDataSourceCost: number =
    planData?.per_additional_data_source_addon?.per_item_monthly_price_usd ?? 0;

  let totalUsers = existingUserCount || 1;
  let amount_due = 0;
  let adjustment_amount = 0;
  if (editType === "add-seats") {
    totalUsers += additionalSeats;
    amount_due =
      (additionalSeats * oneUserCost +
        (selectedDataSources.length - 1) * additionalSeats * additionalDataSourceCost) *
      (selectedBillingInterval === "annually" ? 12 : 1);
  } else if (editType === "remove-seats") {
    totalUsers -= additionalSeats;
    amount_due =
      (additionalSeats * oneUserCost +
        (selectedDataSources.length - 1) * additionalSeats * additionalDataSourceCost) *
      (selectedBillingInterval === "annually" ? 12 : 1);
  } else if (editType === "edit-billing-interval") {
    amount_due = dueNowCost - totalMonthlyCost - annualDiscount;
    adjustment_amount = totalMonthlyCost + annualDiscount;
  } else if (editType === "upgrade-to-pro") {
    amount_due = dueNowCost - oldStarterSubscriptionPrice;
    adjustment_amount = oldStarterSubscriptionPrice;
  }

  const { data, isLoading } = useBillingDetails(
    {
      temporary_front_end_estimated_amount: amount_due,
      plan_type: planData?.plan_type,
      billing_interval: planData?.billing_interval,
      num_users: totalUsers,
      num_data_sources: selectedDataSources.length,
      coupon_discount_percentage: discountPercentage,
    },
    { enabled: Boolean(planData) }
  );

  if (!planData) {
    return null;
  }

  let coupon_discount_price = 0;
  if (data?.billing_details?.is_refund) {
    amount_due = data?.billing_details?.existing_credits || amount_due;
  } else {
    amount_due =
      data?.billing_details?.amount_due === undefined
        ? amount_due
        : data?.billing_details?.amount_due;
    adjustment_amount = data?.billing_details?.existing_credits || adjustment_amount;
  }

  const discount_coupon = data?.billing_details?.discount_coupon;
  coupon_discount_price = data?.billing_details?.discount_price || 0;

  let totalPerMonth = 0;
  let proratedDiscount = 0;

  const fullCost = oneUserCost * additionalSeats * cycleMultiplier;

  if (editType === "add-seats" && data) {
    totalPerMonth = fullCost / cycleMultiplier;
    proratedDiscount = fullCost - data?.billing_details.amount_total;
  }

  if (editType === "remove-seats" && data) {
    totalPerMonth = fullCost / cycleMultiplier;
    proratedDiscount = fullCost - amount_due;
  }

  return isLoading ? (
    <StyledSkeleton active paragraph={{ rows: 7 }} title={false} />
  ) : (
    <>
      <Row>
        <span style={{ fontWeight: "normal" }}>
          {editType === "add-seats"
            ? `${additionalSeats} additional ${
                planNameFromType(planData.plan_type) ?? ""
              } User${pluralize(additionalSeats)} ($${numberWithCommas(
                nonDiscountedOneUserCost
              )} user/month)`
            : editType === "remove-seats"
            ? `${additionalSeats} ${planNameFromType(planData.plan_type) ?? ""} User${pluralize(
                additionalSeats
              )} ($${numberWithCommas(nonDiscountedOneUserCost)} user/month)`
            : `${existingUserCount || 1} User${pluralize(existingUserCount)}`}
        </span>
        <span>
          {editType === "add-seats"
            ? `$${numberWithCommas(nonDiscountedOneUserCost * additionalSeats)}`
            : editType === "remove-seats"
            ? `$${numberWithCommas(nonDiscountedOneUserCost * additionalSeats)}`
            : `$${numberWithCommas(
                usersCost + (selectedBillingInterval === "annually" ? annualDiscount : 0)
              )}`}
        </span>
      </Row>

      {dataSourceCost > 0 && (
        <Row>
          <span style={{ fontWeight: "normal" }}>Additional data sources</span>
          <span>${numberWithCommas(dataSourceCost)}</span>
        </Row>
      )}

      {annualDiscount > 0 && !(editType === "add-seats" || editType === "remove-seats") ? (
        <Row style={{ color: COLORS.discountGreen }}>
          <span style={{ fontWeight: "normal" }}>Annual plan savings</span>
          <span style={{ fontWeight: "normal" }}>-${numberWithCommas(annualDiscount)}</span>
        </Row>
      ) : null}

      {nonDiscountedOneUserCost !== oneUserCost &&
      (editType === "add-seats" || editType === "remove-seats") ? (
        <Row style={{ color: COLORS.discountGreen }}>
          <span style={{ fontWeight: "normal" }}>Annual plan savings</span>
          <span style={{ fontWeight: "normal" }}>
            -${numberWithCommas((nonDiscountedOneUserCost - oneUserCost) * additionalSeats)}
          </span>
        </Row>
      ) : null}

      {discountPercentage && (
        <Row style={{ color: COLORS.discountGreen }}>
          <span style={{ fontWeight: "normal" }}>{discountPercentage}% Discount</span>
          <span style={{ fontWeight: "normal" }}>-${numberWithCommas(discountPrice)}</span>
        </Row>
      )}

      {!discountPercentage && discount_coupon && coupon_discount_price > 0 && (
        <Row style={{ color: COLORS.discountGreen }}>
          <span style={{ fontWeight: "normal" }}>{discount_coupon} applied</span>
          <span style={{ fontWeight: "normal" }}>-${numberWithCommas(coupon_discount_price)}</span>
        </Row>
      )}

      <hr style={{ borderTop: `1px solid ${COLORS.black45}` }} />

      {!(editType === "add-seats" || editType === "remove-seats") && (
        <>
          <Row className="large">
            <span>Total per month</span>
            <span>${numberWithCommas(totalMonthlyCost)}</span>
          </Row>

          {selectedBillingInterval === "annually" && (
            <Row>
              <span>Total paid annually</span>
              <span>${numberWithCommas(dueNowCost)}</span>
            </Row>
          )}
        </>
      )}

      {(editType === "add-seats" || editType === "remove-seats") && (
        <Row>
          <span>Total per month</span>
          <span>${numberWithCommas(totalPerMonth)}</span>
        </Row>
      )}

      {editType === "add-seats" && (
        <MultiRowWhiteBG>
          {adjustment_amount > 0 && (
            <Row style={{ color: COLORS.discountGreen }}>
              <span style={{ fontWeight: "normal" }}>Existing credits applied</span>
              <span style={{ fontWeight: "normal" }}>-${numberWithCommas(adjustment_amount)}</span>
            </Row>
          )}
          {proratedDiscount !== 0 && (
            <Row style={{ color: COLORS.discountGreen }}>
              <span style={{ fontWeight: "normal" }}>
                Prorated discount for the current billing cycle
              </span>
              <span style={{ fontWeight: "normal" }}>
                -$
                {numberWithCommas(proratedDiscount)}
              </span>
            </Row>
          )}
          <Row className="large">
            <div style={{ marginRight: "12px" }}>
              Amount due now
              <div className="small" style={{ marginRight: "5px", maxWidth: "300px" }}>
                {`Your card ending in ${userSubscription?.subscription_details?.cc_last_4_digits} will be charged now`}
              </div>
            </div>
            <span>${numberWithCommas(amount_due)}</span>
          </Row>
        </MultiRowWhiteBG>
      )}

      {editType === "remove-seats" && (
        <>
          <MultiRowWhiteBG>
            {proratedDiscount !== 0 && (
              <Row style={{ color: COLORS.discountGreen }}>
                <span style={{ fontWeight: "normal" }}>
                  Prorated discount for the current billing cycle
                </span>
                <span style={{ fontWeight: "normal" }}>
                  -$
                  {numberWithCommas(proratedDiscount)}
                </span>
              </Row>
            )}

            <Row className="large whitebg">
              <div style={{ marginRight: "12px" }}>Refund </div>
              <span>${numberWithCommas(amount_due)}</span>
            </Row>

            <Row>
              <div className="small" style={{ marginRight: "5px", maxWidth: "300px" }}>
                prorated refund for the current billing cycle (will be credited to your Coefficient
                billing account)
              </div>
            </Row>
          </MultiRowWhiteBG>
        </>
      )}

      {editType === "edit-billing-interval" && (
        <>
          <MultiRowWhiteBG>
            <Row>
              <span style={{ marginRight: "5px" }}>
                Adjustment for remainder of current billing period
              </span>
              <span>-${numberWithCommas(adjustment_amount)}</span>
            </Row>

            <Row>
              <div style={{ marginRight: "12px" }}>
                Due now{" "}
                <div className="small" style={{ marginRight: "5px" }}>
                  You'll be charged on your card ending in{" "}
                  {userSubscription?.subscription_details?.cc_last_4_digits}
                </div>
              </div>
              <span>${numberWithCommas(amount_due)}</span>
            </Row>
          </MultiRowWhiteBG>
        </>
      )}

      {editType === "upgrade-to-pro" && (
        <>
          <MultiRowWhiteBG>
            <Row>
              <span style={{ marginRight: "5px" }}>
                Adjustment for remainder of current billing period
              </span>
              <span>
                -$
                {numberWithCommas(adjustment_amount)}
              </span>
            </Row>

            <Row>
              <div style={{ marginRight: "12px" }}>Due now</div>
              <span>${numberWithCommas(amount_due)}</span>
            </Row>
          </MultiRowWhiteBG>
        </>
      )}
    </>
  );
};

export const PriceSummaryText = () => {
  const { priceSummary, selectedBillingInterval } = useContext(BillingContext) as BContext;

  return (
    <div>
      <p style={{ fontSize: "12px", color: COLORS.black65 }}>
        You'll be charged ${Number(priceSummary.dueNowCost).toLocaleString()}{" "}
        {selectedBillingInterval} until you cancel the subscription. To learn more, see our FAQ
        below or{" "}
        <a
          href="mailto:support@coefficient.io"
          className="underline"
          target="_blank"
          style={{ color: "inherit" }}
        >
          contact us
        </a>
        .
      </p>
      <p style={{ fontSize: "12px", color: COLORS.black65 }}>
        By clicking the "Purchase" button, you understand and agree to the{" "}
        <a
          href="https://coefficient.io/terms"
          target="_blank"
          className="underline"
          style={{ color: "inherit" }}
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href="https://coefficient.io/privacy-policy"
          target="_blank"
          className="underline"
          style={{ color: "inherit" }}
        >
          privacy policy
        </a>
      </p>
    </div>
  );
};
