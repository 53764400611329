import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  ErrorMessage,
  ImportTypeIcon,
  List,
  LoadingBar,
  message,
  Typography,
  WheelIcon,
} from "../components";
import { COLORS } from "../constants";
import { getBaseUrl, sentryCapture, useApiContext, useTrack } from "../utils";

import { Header } from "./Dashboard/components";
import { AddLookerConnection, LookerHelpCard } from "./LookerOAuthContent";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 50px;
`;

const { Item } = List;

const DataSourceSelections = styled.div`
  border-radius: 6px;
  cursor: default !important;

  .ant-list-item {
    .actionswrap {
      visibility: visible !important;
      border-radius: 6px !important;
    }
  }
`;

const StyledItem = styled(Item)`
  && {
    background: ${COLORS.black4};
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 8px;
    padding: 12px 15px;
    border-radius: 6px;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item {
      margin: 12px 15px !important;
      border: 10px;
      background-color: ${COLORS.black4} !important;
      border-radius: 6px !important;
      cursor: default !important;

      .actionswrap {
        visibility: visible !important;
        border-radius: 6px !important;
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black45};
      line-height: 1.25;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    .ant-list-item-meta-description {
      color: ${COLORS.black85};
      font-size: 14px;
      font-weight: bold;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;
const StyledLink = styled(Link)`
  margin: 8px 16px;
`;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin: 0 10px;
  }
`;

const StyledHelpGuide = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto !important;
`;

const FormLayout = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;

  .leftSection {
    flex: 1;
  }

  .rightSection {
    display: none;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 32px;

    .rightSection {
      minwidth: 280px;
      width: 280px;
      display: flex;
    }
  }
`;

const FormGroup = styled.div`
  display: flex;

  label {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }
`;

const HelpGuide = () => {
  return (
    <StyledHelpGuide>
      <WheelIcon></WheelIcon>
      <Button type="link" noPadding>
        <a
          href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker"
          target="_blank"
        >
          Help guide
        </a>
      </Button>
    </StyledHelpGuide>
  );
};

const Layout: React.FC = ({ children }) => (
  <Content>
    <StyledHeader>
      <ImportTypeIcon type="looker" />
      <h1>Looker OAuth</h1>
      <HelpGuide />
    </StyledHeader>
    {children}
  </Content>
);

const getLookerbaseUrl = (url: string) =>
  url?.replace(/^https?:\/\//, "").replace(/(:\d+)?\/?$/, "");

enum LookerOAuthView {
  MANAGE = "manage",
  ADD = "add",
}

export const LookerOAuthManageSettings = () => {
  const { apiClient: api } = useApiContext();
  const queryClient = useQueryClient();
  const track = useTrack();
  useEffect(() => {
    document.title = "Looker OAuth - Coefficient";
    track("looker_workspace_viewed");
  }, []);

  const [view, setView] = useState<LookerOAuthView>(LookerOAuthView.MANAGE);
  const [formError, setFormError] = useState<{
    title: string;
    message: string;
  }>();

  const onViewChange = (view: LookerOAuthView) => {
    setView(view);
    if (view === LookerOAuthView.ADD) {
      track("looker_workspace_new_account_viewed");
    }
  };

  const {
    data: lookerApps,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryCacheKey.LOOKER_APP_INFO],
    queryFn: () => api.listLookerAppInfo().then(response => response?.data?.apps_list),
    staleTime: 0,
    cacheTime: 0,
    retry: 2,
    onError: error => {
      sentryCapture({
        error,
        name: "LookerOAuthManageSettings: GET /api/data_sources/looker/apps",
      });
      message.error("Failed to fetch Looker apps");
    },
  });

  const validationMutation = useMutation({
    mutationFn: async ({
      baseUrl,
      clientId,
      clientSecret,
    }: {
      baseUrl: string;
      clientId: string;
      clientSecret: string;
    }) => {
      const credentials = {
        url: getBaseUrl(baseUrl),
        client_id: clientId,
        client_secret: clientSecret,
      };

      const response = await api.lookerCheckConnection({
        looker_check_connection_request: { credentials },
      });

      if (response.data.error) {
        setFormError({
          title: response.data.title || "Could not verify client id and secret",
          message:
            response.data.source_error ||
            "Could not verify credentials. Please check your inputs and try again.",
        });
        throw new Error("Failed to validate connection");
      }

      const lookerMeta = await api.lookerGetDataSourceOwnerMetaData({
        looker_get_data_source_owner_meta_data_request: { credentials },
      });

      if (lookerMeta.data.already_configured) {
        setFormError({
          title: "Connection already exists",
          message: `Looker OAuth is already configured for this Looker Base URL. Please delete the existing configuration to add a new one`,
        });
        throw new Error("Connection already exists");
      }

      if (!lookerMeta.data.is_admin) {
        setFormError({
          title: "Not an admin",
          message: "Admin client id and secret are required to configure OAuth",
        });
        throw new Error("Not an admin");
      }

      const client = await api.upsertLookerAppInfo({
        upsert_looker_app_info_request: {
          base_url: response.data.base_url!,
          client_id: clientId,
          client_secret: clientSecret,
        },
      });

      track("looker_workspace_new_account_completed", {
        looker_client_app_guid: client.data.client_app_guid,
      });
      message.success("Looker OAuth connection added successfully");
    },
    onSuccess: () => {
      onViewChange(LookerOAuthView.MANAGE);
      queryClient.resetQueries([QueryCacheKey.LOOKER_APP_INFO]);
    },
    onError: (error: Error | { title: string; message: string }) => {
      track("looker_workspace_new_account_failed", {
        error_message: error.message,
      });
      message.error(error.message || "Failed to validate connection");
      sentryCapture({ error, name: "LookerRequestSetup: validationMutation" });
    },
  });

  if (isLoading) {
    return (
      <Layout>
        <LoadingBar />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <ErrorMessage
          title="Failed to load Looker apps"
          message="There was an error loading your Looker apps. Please try again."
          action={() => refetch()}
          actionText="Try again"
        />
      </Layout>
    );
  }

  if (view === "add") {
    return (
      <Layout>
        {formError && <ErrorMessage {...formError} />}
        <FormLayout>
          <Form className="leftSection">
            <FormGroup>
              <AddLookerConnection
                isLoading={validationMutation.isLoading}
                showCancel
                onAdd={options => validationMutation.mutate(options)}
                onError={setFormError}
                onChanges={() => {
                  if (formError) {
                    setFormError(undefined);
                  }
                }}
                onCancel={() => {
                  onViewChange(LookerOAuthView.MANAGE);
                  queryClient.resetQueries([QueryCacheKey.LOOKER_APP_INFO]);
                }}
              />
            </FormGroup>
          </Form>
          <LookerHelpCard />
        </FormLayout>
      </Layout>
    );
  }

  return (
    <Layout>
      <Typography
        fontSize={"18px"}
        lineHeight={"24px"}
        fontWeight={700}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Accounts
      </Typography>
      {(!lookerApps || !lookerApps.length) && (
        <Alert message="No Looker accounts found" type="info" />
      )}
      {lookerApps?.map((appInfo, index) => (
        <DataSourceSelections key={index}>
          <StyledItem onClick={() => null}>
            <Item.Meta
              title="Base URL"
              avatar={<ImportTypeIcon type={"looker"} />}
              description={getLookerbaseUrl(appInfo.base_url)}
            />
            <StyledLink to={`looker-oauth?app_guid=${appInfo.client_app_guid}`}>Manage</StyledLink>
          </StyledItem>
        </DataSourceSelections>
      ))}
      <Button
        style={{ width: 200, marginTop: 16 }}
        onClick={() => onViewChange(LookerOAuthView.ADD)}
      >
        Add Connection
      </Button>
    </Layout>
  );
};
