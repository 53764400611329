import { useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AppThunkDispatch, getClientFlags, logout } from "../actions";
import { QueryCacheKey } from "../api";
import {
  AuthBroadcastChannelMessage,
  AuthContext,
  AuthTokenErrorType,
  BroadcastChannelName,
  useApiContext,
} from "../utils";

import { LoadingRoute } from "./LoadingRoute";
import { Login } from "./Login";
import { OidcLogin } from "./OidcLogin";

export const AUTH_REDIRECT_URL_KEY = "authRedirectUrl";
export const LOGIN_INTENT = "intent";

export const AuthGuard: React.FC = ({ children }) => {
  const queryClient = useQueryClient();

  const { apiClient } = useApiContext();

  const { instance: publicClientApp } = useMsal();

  const dispatch = useDispatch<AppThunkDispatch>();

  const history = useHistory();

  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [loading, setLoading] = useState(true);

  const [isSSOAuthRequired, setIsSSOAuthRequired] = useState(false);

  const {
    data: userSSOInfoResponse,
    isFetching: isFetchingUserSSOInfo,
    isLoading: isLoadingUserSSOInfo,
  } = useQuery([QueryCacheKey.USER_SSO_INFO], () => apiClient.getUserSSOInfo(), {
    enabled: isSSOAuthRequired,
  });

  const authRequired = !location.pathname.startsWith("/upgrade");

  useEffect(() => {
    const authBroadcastChannel = new BroadcastChannel(BroadcastChannelName.AUTH);

    authBroadcastChannel.onmessage = event => {
      const message: AuthBroadcastChannelMessage = event.data;

      if (message.error.type === AuthTokenErrorType.SSO_AUTH_FAILED) {
        queryClient.invalidateQueries([QueryCacheKey.USER_SSO_INFO]);
        setIsSSOAuthRequired(true);
      }
    };

    return () => {
      authBroadcastChannel.close();
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  const redirectOnLoginSuccess = () => {
    if (location.pathname === "/") {
      const redirectUrl = sessionStorage.getItem(AUTH_REDIRECT_URL_KEY);

      if (redirectUrl) {
        sessionStorage.removeItem(AUTH_REDIRECT_URL_KEY);

        return history.push(redirectUrl);
      }

      history.push("/dashboard/spreadsheets");
    }
  };

  const init = async () => {
    if (!authRequired) {
      dispatch(getClientFlags());
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      await dispatch(getClientFlags());

      setIsAuthenticated(true);

      redirectOnLoginSuccess();
    } catch (error: any) {
      if (error.status === 401 && error.error?.message === "SSO_AUTH_FAILED") {
        queryClient.invalidateQueries([QueryCacheKey.USER_SSO_INFO]);
        setIsSSOAuthRequired(true);
      } else if (error.status && [401, 403].includes(error.status)) {
        handleLogout({ preserveLoginRedirectUrl: true });
      } else {
        Sentry.captureException(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async (params?: { preserveLoginRedirectUrl?: boolean }) => {
    if (params?.preserveLoginRedirectUrl) {
      if (location.pathname !== "/") {
        const searchParams = new URLSearchParams(location.search);

        sessionStorage.setItem(AUTH_REDIRECT_URL_KEY, location.pathname + location.search);
        sessionStorage.setItem(LOGIN_INTENT, searchParams.get(LOGIN_INTENT) || "");
        history.push("/");
      }
    }

    setIsAuthenticated(false);

    setIsSSOAuthRequired(false);

    await publicClientApp.clearCache();

    await dispatch(logout());

    apiClient.authLogout();

    history.push("/");
  };

  if (loading || (isSSOAuthRequired && (isFetchingUserSSOInfo || isLoadingUserSSOInfo))) {
    return <LoadingRoute />;
  }

  if (
    isSSOAuthRequired &&
    userSSOInfoResponse &&
    userSSOInfoResponse.data.id_provider &&
    userSSOInfoResponse.data.sso_enabled &&
    !userSSOInfoResponse.data.is_sso_authenticated
  ) {
    return (
      <OidcLogin
        identityProvider={userSSOInfoResponse.data.id_provider}
        onCancel={() => handleLogout()}
        onSuccess={() => {
          queryClient.invalidateQueries([QueryCacheKey.USER_SSO_INFO]);

          setIsSSOAuthRequired(false);

          init();
        }}
      />
    );
  }

  if (!authRequired || isAuthenticated) {
    return (
      <AuthContext.Provider value={{ logout: () => handleLogout() }}>
        {children}
      </AuthContext.Provider>
    );
  }

  return <Login />;
};
