import { DataSource } from "@coeff/api-admin-ext";
import * as Sentry from "@sentry/react";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import { useAdminDataSource, useDeleteAdminDataSourceMutation } from "../../api";
import { COLORS } from "../../constants";
import { track, useApiContext } from "../../utils";
import { EllipsisMenu } from "../Icons";
import { message } from "../message";
import { Tooltip } from "../Tooltip";

import { DeleteConnectionConfirmation } from "./DeleteConnectionConfirmation";

const ActionsWrap = styled.div<{ noBackground?: boolean; noPadding?: boolean }>`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  // z-index: 90;
  padding-right: ${props => (props.noPadding ? "0px" : "6px")};

  background: ${props =>
    props.noBackground
      ? "transparent"
      : "linear-gradient(to right, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 40%);"};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    background: none;
    border: none;
    height: 32px;
    width: 32px;
    opacity: 0.5;
    padding: 8px;
    margin: 0px 4px 0px;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 100%;
    }
    opacity: 1;
    &:hover {
      background: ${COLORS.black9};
    }

    &:disabled {
      opacity: 0.4;
    }
  }
`;

const DataSourceOptionsMenu = styled(Menu)`
  padding-bottom: 0px;
  width: 200px;
  z-index: 1000;

  .ant-dropdown-menu-item {
    color: ${COLORS.black85};

    &:hover {
      background: ${COLORS.coeblue};
    }

    &.ant-dropdown-menu-item-disabled {
      color: ${COLORS.black25};

      &:hover {
        background: none;
      }
    }
  }
`;

type Props = {
  noBackground?: boolean;
  noPadding?: boolean;
  dataSource: DataSource;
  currentPage?: string;
  onDeleteCb: () => void;
  onRenameCb: () => void;
};

export const DataSourceItemMenu = ({
  dataSource,
  currentPage,
  onDeleteCb,
  onRenameCb,
  noBackground,
  noPadding,
}: Props) => {
  const { mutateAsync: deleteDataSource, isLoading: isLoadingDeleteDataSource } =
    useDeleteAdminDataSourceMutation();

  const { DEPRECATED_api } = useApiContext();

  const [deleting, setDeleting] = useState<boolean>(false);

  const [showDeleteWarn, setShowDeleteWarn] = useState<boolean>(false);

  const { data: adminDataSource } = useAdminDataSource(dataSource.data_source_id!, {
    enabled: showDeleteWarn,
  });

  const isOwner = true; // admin allways has permission ? dataSource.user?.user_id === event_props.user_id;

  const deleteDataSourceConnection = async () => {
    try {
      if (dataSource && dataSource.data_source_id !== undefined) {
        const id = dataSource.data_source_id;
        setDeleting(true);
        await deleteDataSource({ data_source_id: id });
        track(DEPRECATED_api, "conn_deleted", {
          data_source_id: dataSource.data_source_id,
          import_data_source: dataSource.data_source_type,
        });
        onDeleteCb();
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error("Error while deleting this connection");
    }
    setDeleting(false);
    setShowDeleteWarn(false);
  };

  const renderDataSourceMenu = () => (
    <DataSourceOptionsMenu>
      <DataSourceOptionsMenu.Item key="rename" onClick={onRenameCb} disabled={!isOwner}>
        <Tooltip
          title={!isOwner ? "Shared connections can only be renamed by the owner." : ""}
          overlayStyle={{ maxWidth: "200px" }}
        >
          <div>Rename</div>
        </Tooltip>
      </DataSourceOptionsMenu.Item>
      <DataSourceOptionsMenu.Item
        key="delete"
        onClick={() => setShowDeleteWarn(true)}
        disabled={!isOwner}
      >
        <Tooltip
          title={!isOwner ? "Shared connections can only be deleted by the owner." : ""}
          overlayStyle={{ maxWidth: "200px" }}
        >
          <div>Delete</div>
        </Tooltip>
      </DataSourceOptionsMenu.Item>
    </DataSourceOptionsMenu>
  );

  return (
    <>
      <ActionsWrap
        noBackground={noBackground}
        noPadding={noPadding}
        className="actionswrap ellipsis"
      >
        <div onClick={e => e.stopPropagation()}>
          <Dropdown
            overlay={renderDataSourceMenu}
            trigger={["click"]}
            getPopupContainer={item => item}
          >
            <button>
              <EllipsisMenu fill={COLORS.black} />
            </button>
          </Dropdown>
        </div>
      </ActionsWrap>

      <DeleteConnectionConfirmation
        dataSource={adminDataSource ?? dataSource}
        showDeleteConfirmation={showDeleteWarn}
        setShowDeleteConfirmation={setShowDeleteWarn}
        deleteConnection={deleteDataSourceConnection}
        isDeleting={deleting}
      />
    </>
  );
};
