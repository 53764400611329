import { InfoCircleFilled } from "@ant-design/icons";
import { DataSource } from "@coeff/api";
import { DataSource as DataSourceAdmin } from "@coeff/api-admin-ext";
import React, { useState } from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";
import { pluralize } from "../../utils";
import { Checkbox } from "../Checkbox";
import { ConfirmBox } from "../ConfirmBox";

interface DeleteConnectionConfirmationProps {
  dataSource?: DataSource | DataSourceAdmin;
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: (_: boolean) => any;
  deleteConnection: () => any;
  isDeleting?: boolean;
}

const DeleteCheckbox = styled(Checkbox)`
  margin: 20px 0px 0px;
`;

const Section = styled.section`
  margin-top: 15px;
  background: ${COLORS.orangeBg};
  border-radius: 5px;
`;

const StyledPanel = styled.div`
  padding: 10px;

  .header {
    display: flex;
    line-height: 20px;

    .sectionIcon {
      color: ${COLORS.orange};
      margin-right: 5px;
    }

    .title {
      flex: 1;
      font-weight: bold;
    }
  }
  .body {
    margin-top: 8px;
    margin-left: 20px;
    line-height: 18px;
  }
`;

export const DeleteConnectionConfirmation = ({
  dataSource,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  deleteConnection,
  isDeleting,
}: DeleteConnectionConfirmationProps) => {
  const numOwnDataImportsForSource: number = dataSource?.data_source_stats?.num_data_imports ?? 0;
  const numSharedDataImportsForSource: number =
    dataSource?.data_source_stats?.num_shared_data_imports ?? 0;
  const isDataImportExistForSource: boolean =
    numOwnDataImportsForSource > 0 || numSharedDataImportsForSource > 0;

  const [deleteConfirmHighlight, setDeleteConfirmHighlight] = useState<boolean>(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState<boolean>(false);
  const resetDeleteConfirmation = () => {
    setDeleteConfirmed(false);
    setDeleteConfirmHighlight(false);
  };

  const importCount = numOwnDataImportsForSource + numSharedDataImportsForSource;

  const pluralizedImportText = `${importCount} import${pluralize(importCount)}`;
  return (
    <ConfirmBox
      confirmLoading={isDeleting}
      cancelButtonProps={{
        type: "link",
      }}
      maskClosable={true}
      closable={true}
      okText="Delete"
      okButtonProps={{ type: "text", style: { color: COLORS.red } }}
      title="Delete Connection"
      visible={showDeleteConfirmation}
      onCancel={e => {
        e.stopPropagation();
        setShowDeleteConfirmation(false);
        resetDeleteConfirmation();
      }}
      onOk={e => {
        e.stopPropagation();

        if (!isDataImportExistForSource || deleteConfirmed) {
          deleteConnection();
        } else {
          setDeleteConfirmHighlight(true);
        }
      }}
    >
      Are you sure you want to delete this connection?
      {isDataImportExistForSource && (
        <>
          <Section>
            <StyledPanel>
              <div className="header">
                <div className="sectionIcon">
                  <InfoCircleFilled />
                </div>
                <div className="title">Warning!</div>
              </div>
              <div className="body">
                {`${pluralizedImportText} will stop refreshing if this connection is deleted.`}
                {numSharedDataImportsForSource > 0 && (
                  <>
                    <br />
                    <br />
                    This connection is shared with your team.
                  </>
                )}
              </div>
            </StyledPanel>
          </Section>
          <DeleteCheckbox
            className={deleteConfirmHighlight ? "error" : ""}
            checked={deleteConfirmed}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e => {
              e.stopPropagation();
              setDeleteConfirmHighlight(!e.target.checked);
              setDeleteConfirmed(e.target.checked);
            }}
          >
            Got it, please proceed
          </DeleteCheckbox>
        </>
      )}
    </ConfirmBox>
  );
};
