import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as z from "zod";

import { Steps, Button, Input, Typography, Loader } from "../components";
import { WheelIcon, FlexColumn, PlayIcon } from "../components";
import { COLORS } from "../constants";

const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.coeblue};
  margin: 8px 0px;
  padding: 20px;
  border-radius: 6px;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StyledInfoCard = styled.div`
  background: white;
  border-radius: 6px;
  padding: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LOOKER_STEPS = [
  `1. From the Looker home page, click "Admin" and scroll down to "Users”,`,
  `2. Click on the user's profile account`,
  `3. Locate the "API Keys" field and click "Edit Keys”`,
  `4. Click the "New API Key" button to generate a new Client ID and Secret`,
];

const DEFAULT_ERROR = {
  baseurl: "Base URL is required",
  clientId: "Client ID is required",
  clientSecret: "Client Secret is required",
};

const lookerFormSchema = z.object({
  baseUrl: z
    .string({
      required_error: DEFAULT_ERROR.baseurl,
    })
    .min(1, { message: DEFAULT_ERROR.baseurl }),
  clientId: z
    .string({
      required_error: DEFAULT_ERROR.clientId,
    })
    .min(1, DEFAULT_ERROR.clientId),
  clientSecret: z
    .string({
      required_error: DEFAULT_ERROR.clientSecret,
    })
    .min(1, DEFAULT_ERROR.clientSecret),
});

type FormData = z.infer<typeof lookerFormSchema>;

export const AddLookerConnection: React.FC<{
  isLoading: boolean;
  showCancel?: boolean;
  onAdd: (fileds: { baseUrl: string; clientId: string; clientSecret: string }) => void;
  onError: (error?: { title: string; message: string }) => void;
  onCancel?: () => void;
  onChanges?: () => void;
}> = ({ isLoading, showCancel, onCancel, onAdd, onError, onChanges }) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(lookerFormSchema),
  });

  const formValues = watch();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    if (hasError) {
      onError({ title: "Invalid form", message: "Please fill out all required fields" });
    } else {
      onError(undefined);
    }
  }, [hasError]);

  useEffect(() => {
    onChanges?.();
  }, [formValues.baseUrl, formValues.clientId, formValues.clientSecret]);

  const onSubmit = handleSubmit(data => {
    if (!Object.keys(errors).length) {
      onAdd(data);
      return;
    }
  });

  const steps = [
    {
      title: (
        <>
          <Typography
            style={{ fontWeight: 700, lineHeight: "22px", fontSize: "16px" }}
            color={!currentStep ? "textPrimary" : "textSecondary"}
          >
            Enter Your Base URL
          </Typography>
          {showCancel && (
            <Button
              style={{ width: "92px", position: "absolute", right: "0px", top: "0px" }}
              type="link"
              onClick={onCancel}
            >
              Cancel Setup
            </Button>
          )}
        </>
      ),
      description:
        currentStep === 0 ? (
          <StyledStepContainer>
            <div>
              <Typography fontSize="14px" lineHeight={"17px"}>
                Looker Base URL
              </Typography>
              <Controller
                name="baseUrl"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="https://your-instance.looker.com"
                    status={errors.baseUrl ? "error" : undefined}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
              <Typography fontSize="12px" lineHeight={"14px"} color="textSecondary">
                The URL you use to access your Looker account
              </Typography>
              {errors.baseUrl && <Typography color="error">{errors.baseUrl.message}</Typography>}{" "}
            </div>

            <ButtonContainer>
              <Button
                style={{ marginTop: 40 }}
                type="primary"
                disabled={!formValues.baseUrl}
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                Next
              </Button>
            </ButtonContainer>
          </StyledStepContainer>
        ) : (
          <>
            <Button
              style={{ margin: 0, justifyContent: "flex-start" }}
              type="link"
              onClick={() => {
                setCurrentStep(0);
              }}
            >
              Edit
            </Button>
          </>
        ),
    },
    {
      title: (
        <Typography
          style={{ fontWeight: 700, lineHeight: "22px", fontSize: "16px" }}
          color={currentStep === 1 ? "textPrimary" : "textSecondary"}
        >
          Enter Client ID & Secret
        </Typography>
      ),
      description:
        currentStep === 1 ? (
          <StyledStepContainer>
            <Typography fontSize="14px" lineHeight={"20px"} fontWeight={500}>
              You must be a Looker admin to access your client ID & secret in Looker.
            </Typography>
            <StyledInfoCard>
              <FlexRow>
                <Typography fontSize="14px" lineHeight={"20px"} fontWeight={800}>
                  How to find your client ID and secret in Looker
                </Typography>
                <Button style={{ margin: 0 }} type="link">
                  watch video
                </Button>
              </FlexRow>
              <Typography fontSize="14px" lineHeight={"20px"} fontWeight={500}>
                {LOOKER_STEPS.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </Typography>
            </StyledInfoCard>

            <div>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <Typography fontSize="14px" lineHeight={"17px"}>
                  Client ID
                </Typography>
                <Typography color="textLink" lineHeight={"22px"} fontSize={"12px"}>
                  <a
                    tabIndex={-1}
                    href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
                    target="_blank"
                  >
                    How to find
                  </a>
                </Typography>
              </FlexRow>
              <Controller
                name="clientId"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.clientId ? "error" : undefined}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
              {errors.clientId && <Typography color="error">{errors.clientId.message}</Typography>}
            </div>

            <div>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <Typography fontSize="14px" lineHeight={"17px"}>
                  Client Secret
                </Typography>
                <Typography color="textLink" lineHeight={"22px"} fontSize={"12px"}>
                  <a
                    tabIndex={-1}
                    href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
                    target="_blank"
                  >
                    How to find
                  </a>
                </Typography>
              </FlexRow>
              <Controller
                name="clientSecret"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    status={errors.clientSecret ? "error" : undefined}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
              {errors.clientSecret && (
                <Typography color="error">{errors.clientSecret.message}</Typography>
              )}
            </div>

            <ButtonContainer>
              <Button
                style={{ margin: 0, minWidth: 90 }}
                type="primary"
                disabled={isLoading}
                onClick={onSubmit}
              >
                {isLoading ? <Loader color="white" /> : "Connect"}
              </Button>
            </ButtonContainer>
          </StyledStepContainer>
        ) : (
          <noscript />
        ),
    },
  ];

  return <Steps direction="vertical" size="small" current={currentStep} items={steps} />;
};

const HelperVideoThump = styled.div`
  background-image: url("../static/looker-video-thumb.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  width: 280px;
  height: 158px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;

  :hover {
    svg {
      scale: 1.2;
    }
  }
`;

const StyledParagraph = styled.div`
  color: ${COLORS.black45};
`;

export const LookerHelpCard = () => {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <FlexColumn className="rightSection">
      <Typography
        style={{ fontWeight: 700, lineHeight: "24px", fontSize: "16px" }}
        color={"textPrimary"}
      >
        Need help?
      </Typography>
      <StyledParagraph>
        <Typography
          style={{ lineHeight: "14px", fontSize: "14px", marginTop: 6, color: "#727272" }}
          fontWeight={600}
          color={"textPrimary"}
        >
          Watch a step-by-step video
        </Typography>
      </StyledParagraph>

      {!playVideo && (
        <HelperVideoThump onClick={() => setPlayVideo(true)}>
          <Button type="primary" style={{ borderRadius: 50 }} icon={<PlayIcon />}></Button>
        </HelperVideoThump>
      )}
      {playVideo && (
        <iframe
          width={280}
          height={158}
          style={{ border: "none", borderRadius: 6, marginTop: 8 }}
          src="https://www.youtube.com/embed/B1Bv2yz-zaE?autoplay=1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      )}

      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <WheelIcon />
        <Button type="link" noPadding>
          <a
            href="https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker#h_01HRCDKYN8NFMZDXTJR5CRPHFV"
            target="_blank"
          >
            Help Guide
          </a>
        </Button>
      </div>
    </FlexColumn>
  );
};
