import { useIsAuthenticated } from "@azure/msal-react";
import { FeatureFlag } from "@coeff/api";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, matchPath, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import {
  CoeLogo,
  Logout,
  PlanTag,
  NavSettings,
  TableNoBackgroundIcon,
  ShareCoefficientIcon,
  AdminIcon,
  SpreadsheetIcon,
  TeamActivity,
  CoePlanIcon,
  Button,
} from "../../../components";
import { COLORS } from "../../../constants";
import { AppState } from "../../../store";
import { formatNumberToUnits, useAuthContext, useTrack } from "../../../utils";
import { useDashboardContext } from "../Dashboard";

const Container = styled.aside`
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  min-width: 240px;
  max-width: 240px;
  padding: 20px 0px;
  border-right: 1px solid #f5f5f5;
  background-color: ${COLORS.coeblue10};
`;

const CoeLogoContainer = styled.div`
  margin: 0px 0px 20px;
  padding: 8px 16px;
`;

const SideBarMenu = styled(Menu)`
  && {
    flex: 1;
    border: none;
    background: transparent;

    a {
      display: flex;
    }

    li {
      display: flex;

      font-size: 14px;
      margin: 0px;
      padding: 12px 16px;
      height: auto;
      position: relative;
      align-items: center;
      font-weight: 500;

      &.ant-menu-item-selected {
        background: transparent;
      }

      &.active {
        color: ${COLORS.coeblue4} !important;
        background: ${COLORS.coeblue11} !important;
      }

      .ant-menu-title-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      svg,
      .coeff__image_icon {
        color: inherit;
        margin-right: 10px;
        width: 40px;
      }

      &:hover {
        color: ${COLORS.black};
        background: ${COLORS.coeblue11} !important;
      }

      a:hover {
        color: inherit;
      }
    }
  }
`;

const SideBarCallout = styled.div`
  height: 102px;
  padding: 12px 12px;
  cursor: pointer;
  margin: 12px;

  border-radius: 6px;
  background-image: url(../../../static/dash-gradient-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: ${COLORS.black85};

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34.1px;
    margin-bottom: 5px;
  }

  div {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-btn {
    flex: 1 1 auto;
    padding: 4px 12px;
    justify-content: flex-start;
  }
`;

const MenuItem = styled(Menu.Item)<{ active?: boolean }>`
  &.ant-menu-item {
    flex: 1;
    background: none;
    color: ${({ active }) => (active ? `${COLORS.coeblue4}` : `${COLORS.black}`)};
    > span {
      margin-left: 0px !important;
    }
  }

  .ant-menu-title-content {
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    color: inherit;
    font-weight: 600;
    margin-top: 2px;
  }
`;

export const Sidebar: React.FC = () => {
  const isMicrosoftAuthenticated = useIsAuthenticated();

  const track = useTrack();

  const { pathname } = useLocation();

  const { url } = useRouteMatch();

  const history = useHistory();

  const { logout } = useAuthContext();

  const [rows, setRows] = useState<number>(0);

  const { getTeamActivity, planType, isGsuiteDomainAdmin } = useDashboardContext();

  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  useEffect(() => {
    const init = async () => {
      const teamActivity = await getTeamActivity();
      // @ts-ignore
      setRows(teamActivity?.total_rows_imported ?? 0);
    };

    init();
  }, []);

  const signOut = () => {
    track("webapp_logout_clicked");
    logout();
  };

  const settingsClick = () => {
    track("workspace_settings_button_clicked");
    history.push(`${url}/settings`);
  };

  const isSpreadsheets = matchPath(pathname, `${url}/spreadsheets`) !== null ? true : undefined;
  const isDatasets = matchPath(pathname, `${url}/datasets`) !== null ? true : undefined;
  const isActivity = matchPath(pathname, `${url}/activity`) !== null ? true : undefined;
  const isAdmin = matchPath(pathname, `${url}/admin`) !== null ? true : undefined;

  const isInviteCoworkers =
    matchPath(pathname, `${url}/invite-coworkers/webapp-sidebar`) !== null ? true : undefined;
  const isBilling = matchPath(pathname, `${url}/billing`) !== null ? true : undefined;

  return (
    <Container>
      <CoeLogoContainer>
        <Link to={`${url}/spreadsheets`}>
          <CoeLogo />
        </Link>
      </CoeLogoContainer>

      <SideBarMenu key={pathname}>
        <Link to={`${url}/spreadsheets`}>
          <MenuItem className={isSpreadsheets ? "active" : ""} icon={<SpreadsheetIcon />}>
            Spreadsheets
          </MenuItem>
        </Link>

        {!isMicrosoftAuthenticated ? (
          <Link to={`${url}/datasets`}>
            <MenuItem className={isDatasets ? "active" : ""} icon={<TableNoBackgroundIcon />}>
              Datasets
            </MenuItem>
          </Link>
        ) : null}

        <Link to={`${url}/activity`}>
          <MenuItem className={isActivity ? "active" : ""} icon={<TeamActivity />}>
            Team Activity
          </MenuItem>
        </Link>

        {isGsuiteDomainAdmin && (
          <Link to={`${url}/admin`}>
            <MenuItem className={isAdmin ? "active" : ""} icon={<AdminIcon />}>
              Admin
            </MenuItem>
          </Link>
        )}

        <Link to={`${url}/invite-coworkers/webapp-sidebar`}>
          <MenuItem className={isInviteCoworkers ? "active" : ""} icon={<ShareCoefficientIcon />}>
            Invite Coworkers
          </MenuItem>
        </Link>

        {!clientFlags?.force_hide_billing_ui && (
          <Link
            to={`${url}/billing/subscription`}
            onClick={() => track("billing_plan_chooser_invoked", { event_from: "left_nav" })}
          >
            <MenuItem className={isBilling ? "active" : ""} icon={<CoePlanIcon />}>
              Plans & Billing
              <div>
                {planType && ["starter", "pro"].includes(planType) && (
                  <PlanTag planType={planType} />
                )}
              </div>
            </MenuItem>
          </Link>
        )}
      </SideBarMenu>

      {!!rows && (
        <Link to={`${url}/activity`}>
          <SideBarCallout>
            <h2>{formatNumberToUnits(rows, "", 1).replace(".0", "")} 🎉</h2>
            <div>Rows imported using Coefficient!</div>
          </SideBarCallout>
        </Link>
      )}

      <FooterContainer>
        <Button type="text" onClick={settingsClick}>
          <NavSettings /> Settings
        </Button>

        <Button type="text" onClick={signOut}>
          <Logout /> Logout
        </Button>
      </FooterContainer>
    </Container>
  );
};
