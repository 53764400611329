import { DataSourceType } from "@coeff/api";

import * as countriesJSON from "./countries.json";
import enUS from "./en-US.json";

export * from "./csv-payloads";
export * from "./free-domains";

export { BuildFlags } from "./build-flags";

// @ts-ignore
export const COUNTRIES = countriesJSON.default; // Pulled from https://datahub.io/core/country-list

export const ACTIONS = {
  // GSheet Addon Admin related actions
  SET_ADMIN_LOADING: "SET_ADMIN_LOADING",
  SET_DOMAIN: "SET_DOMAIN",
  SET_DOMAINS: "SET_DOMAINS",
  SET_DOMAIN_USERS: "SET_DOMAIN_USERS",
  SET_GLOBAL_SETTING: "SET_GLOBAL_SETTING",
  SET_DOMAIN_IMPORTS: "SET_DOMAIN_IMPORTS",
  SET_DOMAIN_ALERTS: "SET_DOMAIN_ALERTS",

  SET_CLIENT_FLAGS: "SET_CLIENT_FLAGS",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_UPGRADE_TOKEN: "SET_UPGRADE_TOKEN",

  SET_DATA_IMPORTS: "SET_DATA_IMPORTS",
  SET_DATA_IMPORT_RUNS: "SET_DATA_IMPORT_RUNS",
  SET_DOMAIN_USERS_EXT: "SET_DOMAIN_USERS_EXT",
  SET_DOMAIN_ALERTS_EXT: "SET_DOMAIN_ALERTS_EXT",
  SET_DOMAIN_DATA_SOURCES_EXT: "SET_DOMAIN_DATA_SOURCES_EXT",

  SET_BILLING_LOADING: "SET_BILLING_LOADING",
  SET_BILLING_METADATA: "SET_BILLING_METADATA",
  SET_BILLING_SUBSCRIPTION: "SET_BILLING_SUBSCRIPTION",
  SET_BILLING_INVOICES: "SET_BILLING_INVOICES",

  USER_SETTINGS_REQUEST_PENDING: "USER_SETTINGS_REQUEST_PENDING",
  SAVE_USER_SETTINGS_SUCCESS: "SAVE_USER_SETTINGS_SUCCESS",
  SAVE_USER_SETTINGS_FAILED: "SAVE_USER_SETTINGS_FAILED",
  FETCH_USER_SETTINGS_SUCCESS: "FETCH_USER_SETTINGS_SUCCESS",
  FETCH_USER_SETTINGS_FAILED: "FETCH_USER_SETTINGS_FAILED",
};

export const SQL_DATASOURCES = [
  "mysql",
  "postgres",
  "redshift",
  "snowflake",
  "mssql",
  "bigquery",
  "databricks",
];

export const COLORS = {
  // NEUTRALS
  black: "#000000",
  black2: "#FAFAFA",
  black4: "#F5F5F5",
  black6: "#EFEFEF",
  black8: "#EBEBEB",
  black9: "#E8E8E8",
  black15: "#D9D9D9",
  black20: "#CECECE",
  black25: "#BFBFBF",
  black30: "#B0B0B0",
  black35: "#979797",
  black45: "#8C8C8C",
  black65: "#595959",
  black85: "#262626",
  white: "#FFFFFF",

  // PRIMARIES
  coeblue: "#E6F3FF",
  coeblue01: "#ECF6FF",
  coeblue05: "#CFEAFF",
  coeblue1: "#A3D1FF",
  coeblue2: "#7AB8FF",
  coeblue3: "#529DFF",
  coeblue4: "#3053FF",
  coeblueOfficial: "#1890ff",
  coeblue5: "#0054E6",
  coeblue6: "#0040BF",
  coeblue7: "#002E99",
  coeblue8: "#001F73",
  coeblue9: "#00124D",
  coeblue10: "#F8FAFF",
  coeblue11: "#EFF4FE",
  coeblue12: "#DEE9FE",

  // SECONDARIES
  blue: "#D9E6FB",
  cyan: "#13C2C2",
  gold: "#FAAD14",
  gray: "#8C8C8C",
  grayBg: "#F4F5F6",
  green: "#52C41A",
  lime: "#A0D911",
  magenta: "#EB2F96",
  orange: "#FA8C16",
  orange2: "#F8B000",
  purple: "#722ED1",
  purple2: "#8A378C",
  red: "#F5222D",
  volcano: "#FA541C",
  teal: "#13C2C2",
  yellow: "#FAC82A",
  lightGreenBg: "#DBF6F0",
  discountGreen: "#0E8345",
  greenBg: "#D3F2F3",
  greenBg2: "#C4ECEC",
  greenBg3: "#E1F9F9",
  redBg: "#FEE3E5",
  orangeBg: "#FDE4C7",
  orangeBg2: "#FFF3DC",
  orangeBg3: "#FFEAD6",
  purpleBg: "#F1E8FF",
  purpleBg2: "#E4D5FD",
  seafoamBg: "#E0F6F6",
  seafoam: "#08979C",
  yellowBg: "#FFF2D8",
  yellowBg2: "#FFF8EB",
  yellowBg3: "#F9F1DB",
  yellowBg4: "#FBF3DF",
};

export const HEIGHT = {
  xxs: "16px",
  xs: "20px",
  sm: "24px",
  md: "32px",
  lg: "40px",
};

export const ICON_SELECTOR_ICONS = {
  filetext: "file-text",
  team: "team",
  rocket: "rocket",
  alert: "alert",
  bell: "bell",
  book: "book",
  bulb: "bulb",
  calculator: "calculator",
  percentage: "percentage",
  number: "number",
  piechart: "pie-chart",
  linechart: "line-chart",
  barchart: "bar-chart",
  highlight: "highlight",
  calendar: "calendar",
  dashboard: "dashboard",
  experiment: "experiment",
  eye: "eye",
  filter: "filter",
  flag: "flag",
  fire: "fire",
  heart: "heart",
  idcard: "idcard",
  sound: "sound",
  smile: "smile",
  star: "star",
  thunderbolt: "thunderbolt",
  audit: "audit",
  dollar: "dollar",
  robot: "robot",
};

export const ICON_SELECTOR_ICON_COLOR = [
  "cyan",
  "coeblue3",
  "coeblue6",
  "purple",
  "magenta",
  "gray",
  "green",
  "lime",
  "red",
  "volcano",
  "orange",
  "gold",
];

export const IMPORT_TYPE_NAMES: Record<DataSourceType, string> = {
  csv: "CSV",
  google_sheets: "Google Sheets",
  salesforce: "Salesforce",
  mysql: "MySQL",
  redshift: "Redshift",
  postgres: "PostgreSQL",
  snowflake: "Snowflake",
  mssql: "MS SQL Server",
  bigquery: "BigQuery",
  looker: "Looker",
  chargebee: "Chargebee",
  chargify: "Chargify",
  google_analytics: "Google Analytics (UA)",
  hubspot: "HubSpot",
  marketo: "Marketo",
  netsuite: "NetSuite",
  quickbooks: "QuickBooks",
  shopify: "Shopify",
  stripe: "Stripe",
  pipedrive: "Pipedrive",
  tableau: "Tableau",
  jira: "Jira",
  zendesk: "Zendesk",
  google_drive: "Google Drive",
  gpt_copilot: "GPT Copilot",
  google_ads: "Google Ads",
  google_analytics_4: "Google Analytics 4",
  facebook_ads: "Facebook Ads",
  airtable: "Airtable",
  amplitude: "Amplitude",
  gong: "Gong",
  klaviyo: "Klaviyo",
  mailchimp: "Mailchimp",
  mongodb: "MongoDB",
  outreach: "Outreach",
  sendgrid: "Sendgrid",
  excel: "Excel",
  one_drive: "One Drive",
  google_search_console: "Google Search Console",
  gmail: "Gmail",
  cloud_table: "Dataset",
  notion: "Notion",
  greenhouse: "Greenhouse",
  xero: "Xero",
  custom_api: "Connect Any API",
  web_scraper: "Web Scraper",
  linkedin_ads: "LinkedIn Ads",
  facebook_insights: "Facebook Insights",
  instagram_insights: "Instagram Insights",
  linkedin_pages: "LinkedIn Pages",
  youtube_analytics: "YouTube Analytics",
  microsoft_ads: "Microsoft Ads",
  asana: "Asana",
  razorpay: "Razorpay",
  clickup: "ClickUp",
  typeform: "Typeform",
  harvest: "Harvest",
  freshdesk: "Freshdesk",
  paymo: "Paymo",
  google_calendar: "Google Calendar",
  trello: "Trello",
  calendly: "Calendly",
  smartsheet: "Smartsheet",
  reddit_ads: "Reddit Ads",
  surveymonkey: "SurveyMonkey",
  quora_ads: "Quora Ads",
  podio: "Podio",
  gusto: "Gusto",
  intercom: "Intercom",
  etsy: "Etsy",
  pinterest_ads: "Pinterest Ads",
  active_campaign: "Active Campaign",
  basecamp: "Basecamp",
  jotform: "Jotform",
  zohobooks: "Zoho Books",
  coda: "Coda",
  square: "Square",
  zoom: "Zoom",
  webflow: "Webflow",
  bigin: "Bigin",
  zoho_crm: "Zoho CRM",
  lever: "Lever",
  salesloft: "Salesloft",
  woo_commerce: "WooCommerce",
  wordpress: "WordPress",
  bigcommerce: "BigCommerce",
  smartlead: "Smartlead",
  affinity: "Affinity",
  shiprocket: "Shiprocket",
  databricks: "Databricks SQL",
  rippling: "Rippling",
  apollo: "Apollo",
  sage: "Sage Intacct",
  aws_billing: "AWS Billing",
  google_classroom: "Google Classroom",
};

export const OAUTH_DATASOURCES: Array<DataSourceType> = ["bigquery", "google_drive", "one_drive"];

export const SQL_FLOW_REUSE_DATASOURCES: Array<DataSourceType> = ["mongodb"];

export const DATA_SOURCES_USE_SECONDARY_SQL_CONNECTION_FORM: Array<DataSourceType> = [
  "mongodb",
  "databricks",
];

export const MULTIPLE_OAUTH_DATASOURCES: Array<DataSourceType> = [
  "quickbooks",
  "linkedin_ads",
  "linkedin_pages",
  "youtube_analytics",
  "pipedrive",
  "airtable",
  "stripe",
  "google_search_console",
  "facebook_insights",
  "google_ads",
  "mailchimp",
  "outreach",
  "facebook_ads",
  "google_analytics_4",
  "hubspot",
  "salesforce",
  "instagram_insights",
  "jira",
  "google_analytics",
  "notion",
  "zendesk",
  "shopify",
  "microsoft_ads",
  "xero",
  "asana",
  "clickup",
  "typeform",
  "google_calendar",
  "gong",
  "calendly",
  "smartsheet",
  "reddit_ads",
  "surveymonkey",
  "quora_ads",
  "harvest",
  "podio",
  "gusto",
  "intercom",
  "etsy",
  "pinterest_ads",
  "basecamp",
  "zohobooks",
  "square",
  "zoom",
  "webflow",
  "lever",
  "salesloft",
  "bigcommerce",
  "google_classroom",
];

export const DATA_SOURCES_IN_BETA: Array<DataSourceType> = ["amplitude", "gmail", "sage"];

export const TEXT_SIZE = {
  lg: "16px",
  md: "14px",
  sm: "12px",
};

export const TRANSLATIONS = {
  enUS,
};

export const API_ROUTES = {
  alerts: "/api/alerts",
  authCallBack: "/api/auth/google_jwt_callback",
  clientFlags: "/api/client_flags",
  dataImports: "/api/data_imports",
  dataImportRunHistory: `/api/data_imports/:data_import_id/run_history`,
  dashboardSpreadsheets: "/api/dashboard/spreadsheets",
  dashboardTeamActivity: "/api/dashboard/team_activity",
  logout: "/api/auth/logout",
  quickstart: "/api/quickstart",
  user_extras: "/api/user_extras",
  writebacks: "/api/writebacks",
  writebackRunHistory: `/api/writeback/:writeback_id/run_history`,
  writebackRunLog: `/api/writeback_runs/:writeback_id/row_statuses_csv`,
  trackUserProblem: "/api/errors/track_user_problem",
};

export const API_ADMIN_ROUTES = {
  alerts: "/api_admin/alerts",
  dataImports: "/api_admin/data_imports",
  domain: "/api_admin/domain",
  domains: "/api_admin/domains",
  globalSettings: "/api_admin/global_settings",
  quickstarts: "/api_admin/quickstarts",
  users: "/api_admin/users",
  workspaces: "/api_admin/workspaces",
};

export const API_EXT_ADMIN_ROUTES = {
  dataImports: "/api_admin_ext/data_imports",
  dataImportRuns: "/api_admin_ext/data_import_runs",
  users: "/api_admin_ext/users",
  alerts: "/api_admin_ext/alerts",
  dataSources: "/api_admin_ext/data_sources",
};

export const API_AUTH_ROUTES = {
  login: "/api/auth/auth0_callback",
  logout: "/api/auth/logout",
  signup: "/api/auth/signup",
};

export const BILLING_ROUTES = {
  billingPreCheckout: "/api/subscriptions/pre_checkout",
  billingCheckout: "/api/subscriptions/checkout",
  billingInvoices: "/api/subscriptions/invoices",
  billingSubscriptionInfo: "/api/subscriptions/subscription_info",
  billingSubscriptionUpdate: "/api/subscriptions/update",
};

export const KEY_LABEL = {
  google_sheets_file_name: "Spreadsheet",
  data_import_name: "Import Name",
  data_source_name: "Source Name",
  created_by: "Created By",
  data_source_type: "Source",
  data_source_id: "Source",
  last_run_dt: "Last Run",
  last_run_status_type: "Status",
  data_source_sharing: "Sharing",
  is_scheduled: "Scheduled",
  name: "Name",
  email: "Email",
  created_dt: "Created Date",
  num_data_imports: "Imports",
  num_writebacks: "Writebacks",
  data_source_details: "Source Info",
  run_complete_dt: "Time",
  run_status_type: "Status",
  num_rows: "Rows",
  num_rows_cols: "Rows",
  trigger_description: "Trigger",
  connection_status: "Status",
  alert_name: "Alert Name",
};

export const AVATAR_COLORS = [
  COLORS.magenta,
  COLORS.orange,
  COLORS.purple,
  COLORS.green,
  COLORS.red,
  COLORS.volcano,
  COLORS.lime,
  COLORS.gold,
  COLORS.cyan,
];

export const ACTIVITY_AVATAR_COLORS = [
  "#3053FF",
  COLORS.teal,
  COLORS.purple,
  COLORS.gold,
  COLORS.magenta,
  "#00AEFF",
  "#FF6D00",
  "#3ABD82",
  "#FF2E00",
  "#7BCCF6",
];

export const HOURLY = [
  { label: "Every hour", value: 1 },
  { label: "Every 2 hours", value: 2 },
  { label: "Every 4 hours", value: 4 },
  { label: "Every 8 hours", value: 8 },
];

export const WEEKDAYS = [
  { dataKey: 0, label: "Sunday", shortLabel: "Sun" },
  { dataKey: 1, label: "Monday", shortLabel: "Mon" },
  { dataKey: 2, label: "Tuesday", shortLabel: "Tue" },
  { dataKey: 3, label: "Wednesday", shortLabel: "Wed" },
  { dataKey: 4, label: "Thursday", shortLabel: "Thu" },
  { dataKey: 5, label: "Friday", shortLabel: "Fri" },
  { dataKey: 6, label: "Saturday", shortLabel: "Sat" },
];

export const CLOUD_TABLE_ICON_COLORS = {
  cyan: "#ADE7FF",
  blue: "#B2CCFF",
  purple: "#DDC4FF",
  pink: "#FFB9EB",
  aqua: "#9FF1F1",
  green: "#A0E6C7",
  red: "#FFAAAE",
  orange: "#FFBA70",
  yellow: "#FFE07D",
  lime: "#C6F2BB",
};

export const CLOUD_TABLE_ICON_NAMES = {
  bank: "Bank",
  barchart: "BarChart",
  bell: "Bell",
  book: "Book",
  buoy: "Buoy",
  calculator: "Calculator",
  calendar: "Calendar",
  chat: "Chat",
  click: "Click",
  clock: "Clock",
  currency: "Currency",
  diamond: "Diamond",
  document: "Document",
  email: "Email",
  gift: "Gift",
  glasses: "Glasses",
  gold: "Gold",
  heart: "Heart",
  journey: "Journey",
  lab: "Lab",
  lightening: "Lightening",
  lock: "Lock",
  market: "Market",
  piechart: "PieChart",
  rocket: "Rocket",
  speaker: "Speaker",
  star: "Star",
  ufo: "Ufo",
  user: "User",
  web: "Web",
};
