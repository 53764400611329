import { DataImport, GmailImportType, GmailParams } from "@coeff/api";
import React from "react";
import styled from "styled-components";

import {
  FlexColumn,
  FlexRow,
  Paper,
  PaperContent,
  Sparkle2,
  Attachment,
  Mail,
  Typography,
} from "../../../../components";
import { COLORS } from "../../../../constants";

const Content = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: ${COLORS.black85};
`;

type Props = {
  dataImport: DataImport;
};

const GmailImportDetails: React.FC<Props> = ({ dataImport }) => {
  const ppress_params = (dataImport.source_info.ppress_params ?? {}) as GmailParams;

  const {
    query_string,
    user_facing_summary_prompt,
    import_type,
    selected_attachment,
    selected_attachment_tab_name,
  } = ppress_params;

  return (
    <Content>
      <Paper>
        <PaperContent size="small">
          <FlexRow style={{ fontWeight: "bold", justifyContent: "space-between", marginBottom: 8 }}>
            <FlexRow gap={1}>
              <Mail style={{ flexShrink: 0 }} />
              <Typography>Email filter</Typography>
            </FlexRow>
          </FlexRow>

          <div style={{ marginLeft: 25 }}>
            <Typography
              lineClamp={4}
              color={query_string?.length ? "textPrimary" : "textSecondary"}
            >
              {query_string?.length ? query_string : <i>(empty)</i>}
            </Typography>
          </div>
        </PaperContent>
      </Paper>

      {import_type === GmailImportType.Emails ? (
        <Paper variant="info">
          <PaperContent size="small">
            <FlexRow
              style={{ fontWeight: "bold", justifyContent: "space-between", marginBottom: 8 }}
            >
              <FlexRow gap={1}>
                <Sparkle2 style={{ flexShrink: 0 }} />
                <Typography>Extract prompt</Typography>
              </FlexRow>
            </FlexRow>

            <FlexColumn gap={0} style={{ marginLeft: 25 }}>
              <Typography
                lineClamp={4}
                color={user_facing_summary_prompt?.length ? "textPrimary" : "textSecondary"}
              >
                {user_facing_summary_prompt?.length ? user_facing_summary_prompt : <i>(empty)</i>}
              </Typography>
            </FlexColumn>
          </PaperContent>
        </Paper>
      ) : import_type === GmailImportType.BasicAttachment ? (
        <Paper variant="info">
          <PaperContent size="small">
            <FlexRow
              style={{ fontWeight: "bold", justifyContent: "space-between", marginBottom: 8 }}
            >
              <FlexRow gap={1}>
                <Attachment height="16px" width="16px" style={{ flexShrink: 0 }} />
                <Typography>Attachment</Typography>
              </FlexRow>
            </FlexRow>

            <FlexColumn gap={0} style={{ marginLeft: 25 }}>
              <Typography ellipsis>{selected_attachment?.file_name}</Typography>

              {selected_attachment_tab_name ? (
                <Typography ellipsis>Tab: {selected_attachment_tab_name}</Typography>
              ) : null}
            </FlexColumn>
          </PaperContent>
        </Paper>
      ) : null}
    </Content>
  );
};

export default GmailImportDetails;
