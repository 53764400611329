import { DataSource, DataSourceType } from "@coeff/api";

import {
  DATA_SOURCES_WITH_MULTIPLE_CONNECT_MODES,
  MULTIPLE_OAUTH_DATASOURCES,
  OAUTH_DATASOURCES,
} from "./constants";

/* eslint-disable @typescript-eslint/no-unsafe-call */
export const getMaskedConnectionString = (connectionStr: string | undefined): string => {
  try {
    if (connectionStr === undefined) {
      return "*****";
    }
    if (connectionStr.includes("jdbc:databricks")) {
      const passwordToMask = connectionStr.substring(connectionStr.lastIndexOf("PWD=") + 4);

      // @ts-ignore
      return connectionStr.replaceAll(passwordToMask, "*****") as string;
    }
    if (!String(connectionStr).includes("mongodb+srv")) {
      return String(connectionStr);
    }
    // @ts-ignore
    const usernamePass = connectionStr.match(/mongodb\+srv:\/\/([^@]+)/)[1];
    const passwordToMask = usernamePass.substring(usernamePass.lastIndexOf(":") + 1);
    // @ts-ignore
    return connectionStr.replaceAll(passwordToMask, "*****") as string;
  } catch (error) {
    return "*****";
  }
};

export enum TunnelAuthMethod {
  PrivateKey = "Private Key",
  Password = "Password",
}

export type TunnelConfig = {
  tunnel_host: string;
  tunnel_port: number;
  tunnel_username: string;
  tunnel_auth_method?: TunnelAuthMethod;
  tunnel_private_key?: string;
  tunnel_passphrase?: string;
  tunnel_password?: string;
  use_tunnel: boolean;
};

export const extractTunnelConfig = (dataSource?: DataSource): TunnelConfig => {
  return {
    use_tunnel: Boolean(dataSource?.credentials?.use_tunnel),
    tunnel_host: dataSource?.credentials?.tunnel_host ?? "",
    tunnel_port: dataSource?.credentials?.tunnel_port ?? 22,
    tunnel_username: dataSource?.credentials?.tunnel_username ?? "",
    tunnel_private_key: dataSource?.credentials?.tunnel_private_key ?? "",
    tunnel_auth_method:
      (dataSource?.credentials?.tunnel_auth_method as TunnelAuthMethod) ??
      TunnelAuthMethod.PrivateKey,
    tunnel_passphrase: dataSource?.credentials?.tunnel_passphrase ?? "",
    tunnel_password: dataSource?.credentials?.tunnel_password ?? "",
  };
};

export const isOAuthDataSourceConnection = (dataSource: DataSource): boolean => {
  if (DATA_SOURCES_WITH_MULTIPLE_CONNECT_MODES.includes(dataSource.data_source_type)) {
    // The logic to determine if the data source is OAuth can differ based on the data source type
    if (dataSource.data_source_type === DataSourceType.Snowflake) {
      return dataSource.ppress_metadata?.client_id !== undefined;
    } else if (dataSource.data_source_type === DataSourceType.Bigquery) {
      return !dataSource.credentials.client_key_json;
    } else if (dataSource.data_source_type === DataSourceType.Looker) {
      return dataSource.ppress_metadata?.client_app_guid !== undefined;
    }
  }

  return (
    MULTIPLE_OAUTH_DATASOURCES.includes(dataSource.data_source_type) ||
    OAUTH_DATASOURCES.includes(dataSource.data_source_type)
  );
};

export const getLookerBaseUrl = (url: string) =>
  url?.replace(/^https?:\/\//, "").replace(/(:\d+)?\/?$/, "");
