import { Modal as ModalAD, ModalProps as ModalPropsAD } from "antd";
import { ButtonProps } from "antd/lib/button";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { Button } from "./Button";
import { DotsLoader } from "./DotsLoader";

type ModalProps = ModalPropsAD & {
  cancelLoading?: boolean;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  showOk?: boolean;
  showCancel?: boolean;
  cancelButton?: React.ReactNode;
  okButton?: React.ReactNode;
};

const StyledModal = styled(ModalAD)`
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 0px;
      padding: 24px 24px 8px;

      .ant-modal-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .ant-modal-body {
      font-size: 16px;
      color: ${COLORS.black45};
      padding: 8px 24px 24px;
    }

    .ant-modal-footer {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: none;
      display: flex;
      padding: 5px 24px 12px;
      justify-content: space-between;

      .btn-wrap {
        display: flex;
        flex: 1;

        &.cancel {
          text-align: left;
        }

        &.submit {
          displat: flex;
          align-items: center;
          justify-content: center;
        }

        &.ok {
          text-align: right;
        }

        a {
          font-size: 16px;
          font-weight: 500;
          color: ${COLORS.coeblue4};
        }

        &.cancel a {
          font-weight: 600;
        }
      }
    }
  }
`;

export const ConfirmBox: React.FC<ModalProps> = ({
  children,
  maskClosable = false,
  closable = false,
  confirmLoading = false,
  cancelLoading = false,
  okButtonProps,
  showCancel = true,
  showOk = true,
  cancelButton,
  okButton,
  ...props
}) => {
  return (
    <StyledModal
      {...props}
      maskClosable={maskClosable}
      closable={closable}
      width={props.width || 260}
      footer={[
        <div>
          {showCancel && props.onCancel && (
            <div className="btn-wrap cancel" onClick={!cancelLoading ? props.onCancel : () => {}}>
              {cancelButton ?? (
                <Button type="text" noPadding disabled={cancelLoading}>
                  {cancelLoading ? <DotsLoader size="large" /> : props.cancelText || "Cancel"}
                </Button>
              )}
            </div>
          )}
        </div>,

        <div>
          {showOk && props.onOk && (
            <div className="btn-wrap ok" onClick={!confirmLoading ? props.onOk : () => {}}>
              {okButton ?? (
                <Button type="primary" disabled={confirmLoading} {...okButtonProps}>
                  {confirmLoading ? (
                    <DotsLoader color="white" size="large" />
                  ) : (
                    <>{props.okText || "Ok"}</>
                  )}
                </Button>
              )}
            </div>
          )}
        </div>,
      ]}
    >
      {children}
    </StyledModal>
  );
};
