import { CheckCircleFilled } from "@ant-design/icons";
import { LookerConnectionEmailTriggerOptions } from "@coeff/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  ImportTypeIcon,
  Button,
  Typography,
  CoeLogo,
  message,
  Loader,
  FlexRow,
  FlexColumn,
  Radio,
} from "../components";
import { COLORS } from "../constants";
import {
  assertIsDefined,
  DataSourceTypeDisplayNames,
  getBaseUrl,
  sentryCapture,
  useApiContext,
  useTrack,
} from "../utils";

const WholePageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 25px 30px;
  max-width: 1080px;
  margin: 0 auto;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  max-width: 466px;
`;

const PageHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin: 24px;
`;

const CoeLogoContainer = styled.div`
  padding-left: 16px;
`;

const StyledParagraph = styled(Typography)`
  color: ${COLORS.black45};
  text-align: center;
`;

const Tag = styled(Typography)`
  background: ${COLORS.yellow};
  padding: 1px 12px;
  border-radius: 4px;
  font-weight: 700;
`;

const IconWrap = styled.div<{ height?: number; width?: number }>`
  .ant-avatar {
    &.ant-avatar-lg {
      height: ${({ height }) => height || 40}px !important;
      width: ${({ width }) => width || 40}px !important;

      svg {
        height: ${({ height }) => height || 40}px !important;
        width: ${({ width }) => width || 40}px !important;
      }
    }
  }

  .anticon {
    height: ${({ height }) => height || 40}px !important;
    width: ${({ width }) => width || 40}px !important;

    svg {
      height: ${({ height }) => height || 40}px !important;
      width: ${({ width }) => width || 40}px !important;
    }
  }
`;

const StyledRadio = styled(Radio)<{
  isActive?: boolean;
}>`
  display: flex;
  height: auto;
  font-size: 14px;
  color: ${COLORS.black85};
  white-space: normal;
  background-color: ${COLORS.black4};
  border-radius: 4px;
  margin: 0;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid ${({ isActive }) => (isActive ? COLORS.coeblue4 : COLORS.black4)};

  span {
    &.ant-radio {
      height: 16px;
      position: relative;
      margin-right: 5px;

      .ant-radio-checked {
        &::after {
          animation-duration: 0s !important;
        }
      }
    }
  }
`;

type LocationState = {
  name: string;
  uuid: string;
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  isAdmin: boolean;
};
type Views = "oauth" | "skip" | "success";

const mandatoryParams = ["uuid", "baseUrl", "clientId", "clientSecret", "isAdmin", "name"];

const PAGE_EXPIRATION_DELAY = 15_000; // 15 seconds

export enum AuthType {
  OAUTH = "oauth",
  BASIC = "basic",
}

export const OAUTH_OPTIONS = [
  {
    title: "Enable OAuth",
    description: `Allows users from your domain to login to Looker using their own username and password, without having to enter a client ID & secret.`,
    isRecommended: true,
    value: AuthType.OAUTH,
    actionName: "Authorize",
  },
  {
    title: "Not right now",
    description: `Complete the setup using client ID & secret based connection.`,
    value: AuthType.BASIC,
    actionName: "Complete Setup",
  },
];

const hasMandatoryParams = (state: Record<string, any>) =>
  mandatoryParams.every(
    param => state && state[param] !== undefined && state[param] !== null && state[param] !== ""
  );

export const CompleteLookerSetup: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const track = useTrack();
  const timerId = useRef<NodeJS.Timeout>();

  const { apiClient: api } = useApiContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [view, setView] = useState<Views>("oauth");

  const [authType, setAuthType] = useState<AuthType>(AuthType.OAUTH);

  if (!hasMandatoryParams(location.state)) {
    history.replace(`../request-looker/start`);
    clearTimeout(timerId.current);
  }
  const { uuid, baseUrl, clientId, clientSecret, isAdmin, name } = location.state;

  const expireThePage = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = setTimeout(() => {
      const queryParams = new URLSearchParams();
      queryParams.set("uuid", uuid);
      history.replace(`../request-looker/start?${queryParams}`, { state: {} });
    }, PAGE_EXPIRATION_DELAY);
  };

  const onClientIdConnectSuccess = () => {
    track("looker_setup_completed", {
      looker_request_access_uuid: uuid,
      is_looker_admin: isAdmin,
    });
    messageApi.open({
      content: (
        <FlexRow style={{ alignItems: "start" }}>
          <CheckCircleFilled style={{ color: COLORS.green }} />
          <FlexColumn style={{ alignItems: "start" }} gap={0}>
            <Typography fontWeight={700} align="left">
              Connection Successful
            </Typography>
            <Typography align="left">
              We've sent the connection details to <b>{name}</b>
            </Typography>
          </FlexColumn>
        </FlexRow>
      ),
    });
    setView("skip");
    expireThePage();
  };

  const onOauthConnectSuccess = () => {
    track("looker_setup_completed", {
      looker_request_access_uuid: uuid,
      is_looker_admin: true,
      is_oauth_connection: true,
    });
    messageApi.success("Connection Successful");
    setView("success");
    expireThePage();
  };

  useEffect(() => {
    document.title = "Looker Setup - Coefficient";
    if (!isAdmin) {
      setView("skip");
      onClientIdConnectSuccess();
    }
  }, [isAdmin]);

  useQuery({
    queryKey: [QueryCacheKey.LOOKER_REQUEST_INFO_BY_UUID, uuid],
    queryFn: async () => {
      assertIsDefined(uuid);
      const response = await api.lookerFetchUrlBasedAuthDetails({
        looker_fetch_url_based_auth_details_request: {
          attached_uuid: uuid,
        },
      });

      if (response.data.is_expired) {
        throw new Error("Looker request has expired");
      }
      return response.data;
    },
    cacheTime: 0,
    staleTime: 0,
    retry: 2,
    enabled: Boolean(uuid),
    onError: (err: Error) => {
      message.error(err.message || "Failed to fetch Looker request details");
      sentryCapture({
        error: err,
        name: "LookerCompleteSetup: GET /api/data_sources/looker/url_based_auth_details",
      });
    },
  });

  const oauthMutation = useMutation({
    mutationFn: async () => {
      await api.upsertLookerAppInfo({
        upsert_looker_app_info_request: {
          base_url: getBaseUrl(baseUrl),
          client_id: clientId,
          client_secret: clientSecret,
          url_based_auth_uuid: uuid,
        },
      });
      await api.triggerLookerConnEmail({
        trigger_looker_conn_email_request: {
          attached_uuid: uuid,
          trigger_type: LookerConnectionEmailTriggerOptions.OauthConnCreated,
        },
      });
    },
    onSuccess: () => {
      onOauthConnectSuccess();
    },
    onError: error => {
      track("looker_setup_failed", {
        looker_request_access_uuid: uuid,
        is_looker_admin: true,
        is_oauth_connection: true,
      });
      sentryCapture({
        error,
        name: "LookerCompleteSetup: POST /api/data_sources/looker/apps/upsert",
      });
      messageApi.error("Failed to connect");
    },
  });

  const clientIdMutation = useMutation({
    mutationFn: async () => {
      await api.createDataSource({
        create_data_source_request: {
          data_source: {
            credentials: {
              client_id: clientId,
              client_secret: clientSecret,
              url: baseUrl,
              remote_auth_url_uuid: uuid,
            },
            data_source_type: "looker",
            data_source_name: DataSourceTypeDisplayNames.looker,
          },
        },
      });

      await api.triggerLookerConnEmail({
        trigger_looker_conn_email_request: {
          attached_uuid: uuid,
          trigger_type: LookerConnectionEmailTriggerOptions.ClientSecretConnCreated,
        },
      });
    },
    onSuccess: () => {
      track("conn_create_done", {
        data_source: DataSourceTypeDisplayNames.looker,
        import_data_source: "looker",
        via_looker_admin: true,
        looker_version: 2,
        looker_request_access_uuid: uuid,
      });
      onClientIdConnectSuccess();
    },
    onError: error => {
      track("looker_setup_failed", {
        looker_request_access_uuid: uuid,
      });
      sentryCapture({
        error,
        name: "LookerCompleteSetup: POST /api/data_sources",
      });
      messageApi.error("Failed to connect");
    },
  });

  const disabled = oauthMutation.isLoading || clientIdMutation.isLoading;

  const getContent = (view: Views) => {
    switch (view) {
      case "oauth":
        return (
          <Content>
            <FlexRow gap={2}>
              <IconWrap height={60} width={60}>
                <ImportTypeIcon type="looker" />
              </IconWrap>
              <div>
                <Typography fontWeight={700} fontSize={"28px"} lineHeight={"34px"}>
                  Connect to Looker
                </Typography>
              </div>
            </FlexRow>

            <div>
              {OAUTH_OPTIONS.map(options => (
                <StyledRadio
                  value={options.value}
                  key={options.title}
                  checked={options.value === authType}
                  disabled={disabled}
                  onChange={event => setAuthType(event.target.value)}
                  isActive={options.value === authType}
                >
                  <FlexRow
                    gap={0}
                    style={{ justifyContent: "space-between", position: "relative" }}
                  >
                    <Typography
                      fontWeight={700}
                      fontSize={"14px"}
                      lineHeight={"17px"}
                      color="textPrimary"
                    >
                      {options.title}
                    </Typography>
                    {options.isRecommended && <Tag>Recommended</Tag>}
                  </FlexRow>
                  <Typography
                    color="textTertiary"
                    fontSize={"14px"}
                    fontWeight={500}
                    lineHeight={"18px"}
                    style={{ marginTop: 6, color: COLORS.black45 }}
                  >
                    {options.description}
                  </Typography>
                </StyledRadio>
              ))}
            </div>

            <Button
              type="primary"
              disabled={disabled}
              onClick={() => {
                authType === AuthType.OAUTH ? oauthMutation.mutate() : clientIdMutation.mutate();
              }}
            >
              {disabled ? (
                <Loader
                  color="white"
                  size="micro"
                  style={{
                    position: "relative",
                    top: "2px",
                  }}
                />
              ) : (
                "Complete Setup"
              )}
            </Button>
          </Content>
        );
      case "skip":
        return (
          <Content>
            <IconWrap height={60} width={60}>
              <ImportTypeIcon type="looker" />
            </IconWrap>

            <Typography fontSize="28px" lineHeight={"32px"} fontWeight={700}>
              That’s all for now
            </Typography>

            <StyledParagraph fontWeight={500} fontSize="16px" lineHeight={"20px"}>
              We’ve notified <b>{name}</b> that the connection is set up.
            </StyledParagraph>
          </Content>
        );

      case "success":
        return (
          <Content>
            <IconWrap height={60} width={60}>
              <CheckCircleFilled style={{ color: COLORS.green }} />
            </IconWrap>

            <Typography fontSize="28px" lineHeight={"32px"} fontWeight={700}>
              OAuth enabled for your domain
            </Typography>
            <StyledParagraph fontWeight={500} fontSize="16px" lineHeight={"20px"}>
              We have notified <b>{name}</b> that OAuth configurations have been added. The user can
              login into Looker from Coefficient sidebar to create there connection.
            </StyledParagraph>
          </Content>
        );
    }
  };

  return (
    <WholePageLayout>
      <PageHeader>
        <CoeLogoContainer>
          <CoeLogo />
        </CoeLogoContainer>
      </PageHeader>
      {contextHolder}
      <Container>{getContent(view)}</Container>
    </WholePageLayout>
  );
};
